import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Objetivo = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-2" variant="h6" className={classes.subtitle}>
                <span className={classes.item}>2.</span> OBJETIVO DESTE AVISO DE PRIVACIDADE E APRESENTAÇÃO DO ENCARREGADO
            </Title>

            <Typography className={classes.text} variant="body2">
                O presente aviso de privacidade foi desenvolvido em atenção à Lei Federal nº 13.709/2018 (“Lei Geral de Proteção de Dados” ou “LGPD”), com o objetivo de apresentar como utilizamos dados pessoais para
                executar as nossas atividades e esclarecer os direitos assegurados pela LGPD em relação a essas informações.
            </Typography>
            <Typography className={classes.text} variant="body2">
                Ainda, esse aviso apresenta a identidade e os dados de contato da pessoa responsável pelos temas privacidade e proteção de dados no IMPA:

                <Grid
                    container
                    align='center'
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item xs={12} sm={8} lg={6} xl={6} >
                        <TableContainer component="div">
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.bolder} width="50%" align="center">Identidade</TableCell>
                                        <TableCell className={classes.bolder} width="50%" align="center">Canal</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">Thayssa Araujo</TableCell>
                                        <TableCell align="center">privacidade@impa.br</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>
        </>
    )
}

export default Objetivo;