import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import { ObjetoPostalErroService } from '../../services/ObjetoPostal';

import Filters from '../../components/Filters/Filters';
import Text from '../../components/Inputs/Text/Text';
import Select from '../../components/Inputs/Select/Select';

import { download } from '../../helper/FileHelper';
import { removePagingAndSorting } from '../../helper/PaginationHelper';

import { optionsConteudo } from '../../variables/Enums/ObjetoPostal';

export class ObjetoPostalErroFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                numero: '',
                codigoMec: '',
                dataEnvio: '',
                conteudo: '',
            },
            exportPlan: false,
            count: 0,
        }
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            filters: { ...this.state.filters, [e.target.name]: e.target.value }
        });
    };

    // APLICAR PERSISTENCIA
    handleFilterPersistence = (filters) => {
        filters = removePagingAndSorting(filters);

        this.setState({
            filters: {
                numero: filters.find(f => f.field === 'numero') ? filters.find(f => f.field === 'numero').value : "",
                codigoMec: filters.find(f => f.field === 'codigoMec') ? filters.find(f => f.field === 'codigoMec').value : "",
                dataEnvio: filters.find(f => f.field === 'dataEnvio') ? filters.find(f => f.field === 'dataEnvio').value : "",
                conteudo: filters.find(f => f.field === 'conteudo') ? filters.find(f => f.field === 'conteudo').value : "",
            },
            count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
        })
    }

    // FILTROS
    handleFilterApply = () => {
        const f = [];
        const { filters, exportPlan } = this.state;

        if (filters.numero) f.push({ field: 'numero', value: filters.numero })
        if (filters.codigoMec) f.push({ field: 'codigoMec', value: filters.codigoMec })
        if (filters.dataEnvio) f.push({ field: 'dataEnvio', value: filters.dataEnvio })
        if (filters.conteudo) f.push({ field: 'conteudo', value: filters.conteudo })

        if (exportPlan) {
            f.push({ field: 'exportarPlanilha', value: exportPlan });

            ObjetoPostalErroService.export(f)
                .then(res => download(res))
                .finally(() => this.setState({ exportPlan: false }));
        } else {
            this.props.handleFilterChange(f);
        };
    }

    // LIMPAR FILTROS
    handleFilterClear = () => {
        this.setState({
            filters: {
                numero: '',
                codigoMec: '',
                dataEnvio: '',
                conteudo: '',
            },
            exportPlan: false,
            count: 0,
        })

        this.props.handleFilterChange([], false);
    }

    // CONTAGEM DE FILTROS ATIVOS
    handleCountFilters = () => this.state.count;

    render() {
        return (
            <Filters
                handleFilter={this.handleFilterApply}
                handleFilterClear={this.handleFilterClear}
                countFilters={this.handleCountFilters}
            >
                <Grid item container spacing={4}>
                    <Grid item sm={12} lg={2}>
                        <Text
                            label="Código Sedex"
                            name='numero'
                            value={this.state.filters.numero}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <Text
                            label="INEP"
                            name='codigoMec'
                            value={this.state.filters.codigoMec}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <Text
                            label="Data de Envio"
                            name='dataEnvio'
                            value={this.state.filters.dataEnvio}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <Select
                            label="Conteúdo"
                            name='conteudo'
                            value={this.state.filters.conteudo}
                            options={optionsConteudo}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                </Grid>
            </Filters>
        )
    }
}

export default ObjetoPostalErroFilter;