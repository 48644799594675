import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import EscolaService from '../../services/Escola';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from "../../components/Toast/Toast";
import Loading from '../../components/Loading/Loading';

import { patternPhones } from '../../variables/Enums/Telefone';
import ResponsavelInscricao from '../../forms/ResponsavelInscricao/ResponsavelInscricao';
import ButtonsForm from '../../forms/Buttons/ButtonsForm';

import { Layout } from '../../layouts/private/Private';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responsible: {
                id: !isNaN(this.props.match.params.id) ? this.props.match.params.id : '',
            },
            loadingButtonSave: false,
            loading: this.props.match.params.id ? true : false,
            history: { 
                path: null, 
                state: null
            }
        };
    }
    
    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setResponsavelInscricao = r => this.ResponsavelInscricao = r;    

    // SALVAR
    handleSubmit = async () => {
        this.setState({ loadingButtonSave: true });

        const formResponsavelIsValid = await this.ResponsavelInscricao.isValid();
        const formTelefoneIsValid = this.ResponsavelInscricao.Telefone.isValidInFormToSave();

        if ( !formResponsavelIsValid || !formTelefoneIsValid ) {
            this.setState({ loadingButtonSave: false });
            return
        }

        const responsible = {
            nmNome: this.ResponsavelInscricao.state.nome.toUpperCase().trim().replaceAll(/\s+/g, " "),
            nmEmail: this.ResponsavelInscricao.Email.state.email,
            nmCpf: this.ResponsavelInscricao.state.cpf,
            nmCargo: this.ResponsavelInscricao.state.cargo,
            telefones: [...this.ResponsavelInscricao.Telefone.state.phones],
        }

        EscolaService.editResponsavel(this.state.responsible.id, responsible)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Responsável pela Inscrição Atualizado com Sucesso.",
                        type: "success",
                        open: true
                    }
                });

                setTimeout(() => this.handleBack(), 1000);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingButtonSave: false }));
    }

    filterPersistence = (parseLocation) => {
        if (parseLocation.state && parseLocation.state.history) {
            this.setState({ 
                history: { 
                    path: parseLocation.state.history.path, 
                    state: parseLocation.state.history.state 
                } 
            });
        }
    }

    componentDidMount() {
        this.filterPersistence(this.props.location);

        if (this.state.responsible.id) {
            EscolaService.findResponsavel(this.state.responsible.id)
                .then(res => {
                    this.setState({ loading: false });

                    this.ResponsavelInscricao.setState({
                        nome: res.data.nmNome,
                        cpf: res.data.nmCpf,
                        cargo: res.data.nmCargo,
                    })

                    this.ResponsavelInscricao.Email.setState({
                        ...this.state,
                        email: res.data.nmEmail,
                        emailOriginal: res.data.nmEmail,
                    })

                    this.ResponsavelInscricao.Telefone.setState({
                        phones: [...patternPhones(res.data.telefones)],
                    })
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar o Responsável pela Inscrição para Edição.",
                            type: 'error',
                            open: true
                        }
                    })
                })
                .finally(() => this.setState({ loading: false }))
        }
    }

    handleBack = () => {
        if (this.state.history && this.state.history.path) {
            this.props.history.push({
                pathname: `${this.state.history.path}`,
                state: {
                    history: {
                        state: this.state.history.state
                    }
                }
            });
        } else {
            this.props.history.goBack();
        }
    }

    render () {
        const page = this.state.responsible.id ? 'Editar Responsável pela Inscrição' : 'Adicionar Responsável pela Inscrição';
        const links = [
            {
                path: null,
                name: 'Escolas'
            }
        ];

        return (
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>

                <Title>{page}</Title>

                { this.state.loading ? 
                    <Loading />
                :
                    <>
                        <ResponsavelInscricao parentRef={this.setResponsavelInscricao} />
                        <ButtonsForm 
                            onClick={this.handleSubmit} 
                            onBack={this.state.history.path ? () => this.handleBack() : null} 
                            idFocus={`saveButton`} 
                            loading={this.state.loadingButtonSave} 
                        />
                    </>
                }
            </Layout>
        )
    }
}

export default withRouter(Form);