import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

import BannerService from '../../services/Banner';
import { errors } from '../../services/API';

import CheckBox from '../../components/Inputs/CheckBox/CheckBox';
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Text from '../../components/Inputs/Text/Text';
import Boolean from '../../components/Inputs/Boolean/Boolean';
import Toast from "../../components/Toast/Toast";
import Loading from '../../components/Loading/Loading';
import ArquivoDesktop from '../../components/Inputs/File/File';
import ArquivoMobile from '../../components/Inputs/File/File';
import { DateTimePicker } from '../../components/Inputs/DateTimePicker/DateTimePicker';

import ButtonsForm from '../../forms/Buttons/ButtonsForm';

import { 
    isBlank, 
    isBlankHelperText, 
    isDateTimeInvalid,
    isDateTimeHelperText,
    isNotBlank,
} from '../../helper/ValidationHelper';
import { filterPersistence } from '../../helper/ParseStateHelper';
import { formatInTimeZone, parseDateTime } from '../../helper/DateHelper';

import { Layout } from '../../layouts/private/Private';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: {
                id: !isNaN(this.props.match.params.id) ? this.props.match.params.id : '',
                titulo: '',
                descricao: '',
                dataInicio: '',
                dataFim: '',
                ativo: '',
                link: '',
                login: false,
            },
            errors: {
                titulo: false,
                dataInicio: false,
                dataFim: false,
            },
            helpers: {
                titulo: null,
                dataInicio: null,
                dataFim: null,
            },
            loadingButtonSave: false,
            loading: this.props.match.params.id ? true : false,
            history: {
                path: null,
                state: null,
            }
        };
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            banner: {...this.state.banner, [e.target.name]: e.target.name === 'login' ? e.target.checked : e.target.value },
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null }
        });
    };

    
    handleChangeDate = (e, name) => {
        this.setState({
            banner: { ...this.state.banner, [name]: e },
            errors: { ...this.state.errors, [name]: false },
            helpers: { ...this.state.helpers, [name]: null },
        });
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setArquivoDesktop = f => this.ArquivoDesktop = f;
    setArquivoMobile = f => this.ArquivoMobile = f;

    // VALIDAÇÃO
    isValid = () => {
        if (isBlank(this.state.banner.titulo) || (isNotBlank(this.state.banner.dataInicio) && isDateTimeInvalid(this.state.banner.dataInicio)) 
            	|| (isNotBlank(this.state.banner.dataFim) && isDateTimeInvalid(this.state.banner.dataFim)) ) {
            this.setState({ 
                errors: {
                    titulo: isBlank(this.state.banner.titulo) ? true : false,
                    dataInicio: isNotBlank(this.state.banner.dataInicio) && isDateTimeInvalid(this.state.banner.dataInicio) ? true : false,
                    dataFim: isNotBlank(this.state.banner.dataFim) && isDateTimeInvalid(this.state.banner.dataFim) ? true : false,
                },
                helpers: {
                    titulo: isBlank(this.state.banner.titulo) ? isBlankHelperText() : null,
                    dataInicio: isNotBlank(this.state.banner.dataInicio) && isDateTimeInvalid(this.state.banner.dataInicio) ? isDateTimeHelperText() : null,
                    dataFim: isNotBlank(this.state.banner.dataFim) && isDateTimeInvalid(this.state.banner.dataFim) ? isDateTimeHelperText() : null,
                },
            })
            return false
        }
        return true
    }

    // SALVAR
    handleSubmit = () => {
        this.setState({ loadingButtonSave: true });

        const formIsValid = this.isValid();
        const formArquivoDesktopIsValid = this.state.banner.id || this.ArquivoDesktop.isValid();
        const formArquivoMobileIsValid = this.state.banner.id || this.ArquivoMobile.isValid();

        console.log(formIsValid);
        console.log(formArquivoDesktopIsValid);
        console.log(formArquivoMobileIsValid);
        
        if (!formIsValid || !formArquivoDesktopIsValid || !formArquivoMobileIsValid) {
            this.setState({ loadingButtonSave: false });
            return
        }

        const config = { headers: { 'content-type': 'multipart/form-data' } }

        const banner = new FormData();
        banner.append('titulo', this.state.banner.titulo)
        banner.append('descricao', this.state.banner.descricao)
        banner.append('dataInicio', this.state.banner.dataInicio ? formatInTimeZone(this.state.banner.dataInicio) : "")
        banner.append('dataFim', this.state.banner.dataFim ? formatInTimeZone(this.state.banner.dataFim) : "")
        banner.append('ativo', this.state.banner.ativo)
        banner.append('link', this.state.banner.link)
        banner.append('login', this.state.banner.login)
        
        
        if (!this.state.banner.id) {
            banner.append('file', this.ArquivoDesktop.state.file)
            banner.append('fileResponsivo', this.ArquivoMobile.state.file)
        }

        console.log(banner)

        let msgSuccess = '';
        let request = '';

        if (this.state.banner.id) {
            request = BannerService.edit(this.state.banner.id, banner, config);
            msgSuccess = "Banner Atualizado com Sucesso.";
        } else {
            msgSuccess = "Banner Criada com Sucesso.";
            request = BannerService.add(banner, config);
        }

        request
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: msgSuccess,
                        type: "success",
                        open: true
                    }
                });

                setTimeout(() => this.handleBack(), 1000);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
                this.setState({ loadingButtonSave: false });
            })
    }

    componentDidMount() {
        this.setState({ history: filterPersistence(this.props.location) });

        if (this.state.banner.id) {
            BannerService.find(this.state.banner.id)
                .then(res => {
                    this.setState({
                        banner: {
                            id: res.data.cdBanner,
                            titulo: res.data.titulo,
                            descricao: res.data.descricao,
                            dataInicio: res.data.dataInicio ? parseDateTime(res.data.dataInicio) : '',
                            dataFim: res.data.dataFim ? parseDateTime(res.data.dataFim) : '',
                            ativo: res.data.ativo,
                            link: res.data.link,
                            login: res.data.login,
                        },
                        loading: false,
                    });
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar Banner para Edição.",
                            type: 'error',
                            open: true
                        }
                    })

                    this.setState({ loading: false })
                })
        }
    }

    handleBack = () => {
        this.props.history.push({
            pathname: this.state.history && this.state.history.path ? `${this.state.history.path}` : `/admin/banners`,
        });    
    }

    render () {
        const page = this.state.banner.id ? 'Editar Banner' : 'Adicionar Banner';
        const links = [
            {
                path: '/admin/banners',
                name: 'Banners'
            },
        ];

        return (
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>

                <Title>{page}</Title>

                { this.state.loading ? 
                    <Loading />
                :
                    <>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item sm={12} lg={3}>
                                <Text
                                    required
                                    name="titulo"
                                    label="Titulo"
                                    value={this.state.banner.titulo}
                                    error={this.state.errors.titulo}
                                    onChange={this.handleChange}
                                    helperText={this.state.helpers.titulo}
                                />
                            </Grid>
                            <Grid item sm={12} lg={7}>
                                <Text 
                                    name="descricao"
                                    label="Descrição"
                                    value={this.state.banner.descricao}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item sm={12} lg={2}>
                                <DateTimePicker
                                    label="Data Inicio de Exibição"
                                    name='dataInicio'
                                    value={this.state.banner.dataInicio}
                                    error={this.state.errors.dataInicio}
                                    onChange={(e) => this.handleChangeDate(e, 'dataInicio')}
                                    helperText={this.state.helpers.dataInicio}
                                />
                            </Grid>
                            <Grid item sm={12} lg={2}>
                                <DateTimePicker
                                    label="Data Fim de Exibição"
                                    name='dataFim'
                                    value={this.state.banner.dataFim}
                                    error={this.state.errors.dataFim}
                                    onChange={(e) => this.handleChangeDate(e, 'dataFim')}
                                    helperText={this.state.helpers.dataFim}
                                />
                            </Grid>
                            <Grid item sm={6} lg={1}>
                                <Boolean
                                    label="Ativo?"
                                    name='ativo'
                                    value={this.state.banner.ativo}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item sm={12} lg={6}>
                                <Text 
                                    name="link"
                                    label="Link"
                                    value={this.state.banner.link}
                                    onChange={this.handleChange}
                                    disabled={this.state.banner.login} 
                                />
                            </Grid>
                            <Grid item sm={12} lg={3}>
                                <CheckBox
                                    name="login"
                                    label="Redirecionar para Tela de Login"
                                    checked={this.state.banner.login}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        </Grid>
                        { !this.state.banner.id && 
                            <Grid container item sm={12} lg={12} style={{ marginTop: 24, marginBottom: 24 }}>
                                <Grid container item sm={12} lg={3}>
                                    <Paper elevation={3} style={{ padding: 24 }}>
                                        <Typography variant="body2" style={{ marginBottom: 6 }}>
                                            Banner para Dispositivos <b>Mobile</b> (Tamanho Sugerido: <b>590px x 430px</b>):
                                        </Typography>
                                        <ArquivoMobile parentRef={this.setArquivoMobile} />
                                    </Paper>
                                </Grid>
                                <Grid container item sm={12} lg={4}>
                                    <Paper elevation={3} style={{ padding: 24 }} >
                                        <Typography variant="body2" style={{ marginBottom: 6 }}>
                                            Banner para Dispositivos <b>Desktop</b> (Tamanho Sugerido: <b>1440px x 430px</b>):
                                        </Typography>
                                        <ArquivoDesktop parentRef={this.setArquivoDesktop} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        }
                        <Grid container spacing={3} alignItems='center'>
                            <ButtonsForm 
                                onClick={this.handleSubmit} 
                                onBack={this.state.history && this.state.history.path ? () => this.handleBack() : null} 
                                idFocus={`saveButton`} 
                                loading={this.state.loadingButtonSave} 
                            />
                        </Grid>
                    </>
                }
            </Layout>
        )
    }
}

export default withRouter(Form);