import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Container } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';

import EscolaService from '../../services/Escola';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Text from '../../components/Inputs/Text/Text';
import Toast from "../../components/Toast/Toast";
import Loading from '../../components/Loading/Loading';

import Endereco from '../../forms/Endereco/Endereco';
import Email from '../../forms/Email/Email';
import DependenciaAdministrativa from '../../forms/DependenciaAdministrativa/DependenciaAdministrativa';

import Telefone from '../../forms/Telefone/Telefone';
import { patternPhones } from '../../variables/Enums/Telefone';

import ButtonsForm from '../../forms/Buttons/ButtonsForm';

import {
    isBlank,
    isBlankHelperText,
    isMecCodeInvalid,
    isMecCodeHelperText,
    isNameSchoolCompoundInvalid,
    isNameSchoolCompoundHelperText,
    isMecCodeExistOtherSchool,
    isMecCodeNotValid,
    isUfDiffPrefixMecCodeHelperText,
    isMecCodeExistHelperText
} from '../../helper/ValidationHelper';
import { cnpjMask, mecCodeMask } from '../../helper/MaskHelper';
import { filterPersistence } from '../../helper/ParseStateHelper';

import { __ESCOLA_ADICIONAR_OPCOES_AVANCADAS } from '../../security/RoleConfiguration';
import { keycloak } from '../../security/keycloak';
import AuthorizedFunction from '../../security/AuthorizedFunction';

import Private from '../../layouts/private/Private';
import Public from '../../layouts/public/Public';

import EscolaStatus from '../../forms/EscolaStatus/EscolaStatus';

export const EscolaForm = props => {
    const [ keycloak, ] = useKeycloak();

    return (
        <>
            {keycloak.authenticated ?
                <Private>
                    <Form {...props} />
                </Private>
            :
                <Public>
                    <Container component="main" maxWidth="xl" style={{ marginTop: '24px' }}>
                        <Form {...props} />
                    </Container>
                </Public>
            }
        </>
    )
}

export class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            school: {
                id: !isNaN(this.props.match.params.id) ? this.props.match.params.id : '',
                possuiMecCode: "true",
                mecCode: '',
                name: '',
                isInscrita: false,
                conveniadaTotal: false,
            },
            errors: {
                name: false,
              
            },
            helpers: {
                name: null,
                
            },
            loadingButtonSave: false,
            loading: this.props.match.params.id ? true : false,
            responsavel: null,
            history: {
                path: null,
                state: null
            }
        };
    }

    // INPUTS
    handleChange = async e => {
        this.setState({
            school: {
                ...this.state.school,
                [e.target.name]: e.target.name === 'mecCode' ? mecCodeMask(e.target.value) : e.target.value
            },
            errors: { ...this.state.errors, [e.target.name]: false },
            helpers: { ...this.state.helpers, [e.target.name]: false }
        });

        if(!this.props.match.params.id && e.target.name === 'mecCode' && e.target.value.length === 0)
            this.habilitarEndereco()
    };

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setEmail = e => this.Email = e;
    setTelefone = form => this.Telefone = form;
    setEscolaStatus = form => this.EscolaStatus = form;
    setEndereco = form => this.Endereco = form;
    setDependenciaAdministrativa = form => this.DependenciaAdministrativa = form;

    dependenciaAdministrativaComponentChanged = (estado) => {
        this.EscolaStatus.setState({
            ...this.EscolaStatus.state,
            possuiConvenio : estado.possuiConvenio,
            nmDependenciaAdministrativa: estado.adminDep,
            dependenciaConvenio: estado.depConvenio,
        });
    }

    // VALIDAÇÕES
    isValid = async () => {
        const escolaComEsseCodigo = this.state.school.mecCode ? await isMecCodeExistOtherSchool(this.state.school.mecCode) : null;
        const jaTemEscola = escolaComEsseCodigo ? true : false;

        const ufPrefixo = this.state.school.mecCode && !jaTemEscola ? await isMecCodeNotValid(this.state.school.mecCode) : null;
        const ufsDiferentes = ufPrefixo ? !this.Endereco.state.address.UF && this.Endereco.state.address.UF === ufPrefixo.cdUf : false;
        const mecPrefixoInvalido = ufPrefixo ? false : true;

        if ((!this.state.school.id && this.state.school.possuiMecCode === "true" && (isBlank(this.state.school.mecCode) || isMecCodeInvalid(this.state.school.mecCode) || jaTemEscola || mecPrefixoInvalido || ufsDiferentes)) ||
            isBlank(this.state.school.name) || isNameSchoolCompoundInvalid(this.state.school.name)) {
            this.setState({
                errors: {
                    mecCode: !this.state.school.id && this.state.school.possuiMecCode === "true" ? isBlank(this.state.school.mecCode) || isMecCodeInvalid(this.state.school.mecCode) || jaTemEscola || mecPrefixoInvalido || ufsDiferentes ? true : false : false,
                    name: isBlank(this.state.school.name) || isNameSchoolCompoundInvalid(this.state.school.name) ? true : false,
                },
                helpers: {
                    mecCode: !this.state.school.id && this.state.school.possuiMecCode === "true" ? isBlank(this.state.school.mecCode) ? isBlankHelperText() : 
                                isMecCodeInvalid(this.state.school.mecCode) || mecPrefixoInvalido ? isMecCodeHelperText() : jaTemEscola ? isMecCodeExistHelperText(escolaComEsseCodigo.nmEscola) : 
                                    ufsDiferentes ? isUfDiffPrefixMecCodeHelperText() : null : null,
                    name: isBlank(this.state.school.name) ? isBlankHelperText() : isNameSchoolCompoundInvalid(this.state.school.name) ? isNameSchoolCompoundHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }   

    // SALVAR
    handleSubmit = async () => {
        this.setState({ loadingButtonSave: true });

        const formIsValid = await this.isValid();
        const formEmailIsValid = await this.Email.isValid();
        const formEnderecoIsValid = this.Endereco.isValid();
        const formAdmDepIsValid = this.DependenciaAdministrativa.isValid();
        const formTelefoneIsValid = this.Telefone.isValidInFormToSave();
        const formEscolaStatusIsValid = this.EscolaStatus.isValid();

        if (!formIsValid || !formEmailIsValid || !formEnderecoIsValid || !formAdmDepIsValid || !formTelefoneIsValid || !formEscolaStatusIsValid) {
            this.setState({ loadingButtonSave: false });
            return
        }

        const school = {
            cdEscola: (this.state.school.possuiMecCode === "true" || this.state.school.id.includes("999", 0)) && !this.state.school.isInscrita ? this.state.school.mecCode : null,
            nmEscola: this.state.school.name.toUpperCase().trim().replaceAll(/\s+/g, " "),

            nmEmail: this.Email.state.email,

            nmCep: this.Endereco.state.address.zipCode,
            nmEndereco: this.Endereco.state.address.address.toUpperCase().trim(),
            nmNumero: this.Endereco.state.address.number,
            nmComplemento: this.Endereco.state.address.complement,
            nmBairro: this.Endereco.state.address.district.toUpperCase().trim(),
            cdMunicipioRegiao: this.Endereco.state.address.city,

            nmDependenciaAdministrativa: this.DependenciaAdministrativa.state.adminDep,
            cnpj: this.DependenciaAdministrativa.state.cnpj,
            possuiConvenio: this.DependenciaAdministrativa.state.possuiConvenio,
            dependenciaConvenio: this.DependenciaAdministrativa.state.depConvenio,
            dependenciaAlunoConvenio: this.DependenciaAdministrativa.state.tipoConvenio,

            inNova: AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) ? this.EscolaStatus.state.origem : null,
            nmStatus: AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) ? this.EscolaStatus.state.status : null,
            nmJustificativa: AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) ? this.EscolaStatus.state.status === "INVALIDA" ? this.EscolaStatus.state.justificativa.trim() : null : null,

            telefones: [...this.Telefone.state.phones],
        }

        let msgSuccess = '';
        let request = '';

        if (this.state.school.id) {
            request = EscolaService.edit(this.state.school.id, school);
            msgSuccess = "Escola Atualizada com Sucesso.";
        } else {
            msgSuccess = `Escola Criada com Sucesso. ${!keycloak.authenticated ? "A Escola Será Avisada por Email sobre a Validação do Seu Cadastro pela OBMEP, assim como Instruções para Realizar sua Inscrição." : ""}`;
            request = EscolaService.add(school);
        }

        request
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: msgSuccess,
                        type: "success",
                        open: true
                    }
                });

                this.setState({ loadingButtonSave: false });
                setTimeout(() => keycloak.authenticated ? this.handleBack() : this.props.history.push('/'), 3000);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingButtonSave: false }))
    }

    async componentDidMount() {
        this.setState({ history: filterPersistence(this.props.location) });

        if (this.state.school.id) {
            EscolaService.find(this.state.school.id)
                .then(res => {
                    this.setState({
                        school: {
                            id: res.data.cdMecEscola,
                            mecCode: res.data.cdMecEscola,
                            name: res.data.nmEscola,
                          
                            isInscrita: res.data.inscricao !== null,
                            possuiMecCode: "true",
                            
                            adminDep: res.data.nmDependenciaAdministrativa,
                            possuiConvenio : res.data.possuiConvenio+ "",
                            depConvenio : res.data.dependenciaConvenio,
                        },
                        loading: false
                    });

                    this.EscolaStatus.setState({
                        tipoOcorrencia : res.data.tipoOcorrencia,
                        origem: res.data.inNova,
                        status: res.data.nmStatus,
                        justificativa: res.data.nmJustificativa,
                    });

                    this.Email.setState({
                        ...this.Email.state,
                        email: res.data.nmEmail,
                        emailOriginal: res.data.nmEmail,
                    })

                    this.Endereco.findCities(res.data.endereco.municipio.uf.cdUf, res.data.endereco.municipio.cdMunicipioRegiao);
                    this.Endereco.setState({
                        address: {
                            zipCode: res.data.endereco.nmCep,
                            address: res.data.endereco.nmEndereco,
                            number: res.data.endereco.nmNumero,
                            complement: res.data.endereco.nmComplemento,
                            district: res.data.endereco.nmBairro,
                            city: res.data.endereco.municipio.cdMunicipioRegiao,
                            UF: res.data.endereco.municipio.uf.cdUf,
                        }
                    })
                    
                    this.DependenciaAdministrativa.setState({
                        adminDep: res.data.nmDependenciaAdministrativa,
                        possuiConvenio : res.data.possuiConvenio + "",
                        depConvenio : res.data.dependenciaConvenio,
                        tipoConvenio : res.data.dependenciaAlunoConvenio,
                        cnpj : res.data.cnpj ? cnpjMask(res.data.cnpj) : '',
                    })

                    this.Telefone.setState({
                        phones: [...patternPhones(res.data.telefones)],
                    })
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar a Escola para Edição.",
                            type: 'error',
                            open: true
                        }
                    })

                    this.setState({ loading: false })
                })
        }
    }

    handleBack = () => {
        this.props.history.push({
            pathname: `${this.state.history.path}`,
            state: {
                history: {
                    state: this.state.history.state
                }
            }
        });
    }

    validarSeINEPExistente = async () => {
        if (this.state.school.id !== this.state.school.mecCode && this.state.school.mecCode.length === 8) {
            const escolaComEsseCodigo = await isMecCodeExistOtherSchool(this.state.school.mecCode);

            if (escolaComEsseCodigo && !this.state.errors.mecCode) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        mecCode: true,
                    },
                    helpers: {
                        ...this.state.helpers,
                        mecCode: isMecCodeExistHelperText(escolaComEsseCodigo.nmEscola),
                    },
                });

                return true;
            }
        }

        return false;
    }

    validarPrefixoINEPValido = async () => {
        if ((!this.state.school.id || (this.state.school.id && this.state.school.id !== this.state.school.mecCode && this.state.school.id.includes("999", 0))) 
                && this.state.school.mecCode && this.state.school.mecCode.length === 8) {

            const uf = await isMecCodeNotValid(this.state.school.mecCode);

            if (uf) {
                if ((!this.state.school.id && !this.Endereco.state.address.UF) || this.Endereco.state.address.UF === uf.cdUf) {
                    this.Endereco.findCities(uf.cdUf, this.Endereco.state.address.city);
                    this.Endereco.setState({
                        address: {
                            ...this.Endereco.state.address,
                            UF: uf.cdUf,
                        },
                        disabled: {
                            ...this.Endereco.state.disabled,
                            UF: true,
                        }
                    })
                } else {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            mecCode: true,
                        },
                        helpers: {
                            ...this.state.helpers,
                            mecCode: isUfDiffPrefixMecCodeHelperText(),
                        },
                    });
                }
            } else if (!uf && !this.state.errors.mecCode) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        mecCode: true,
                    },
                    helpers: {
                        ...this.state.helpers,
                        mecCode: isMecCodeHelperText(),
                    },
                });
            }
        }
    }

    habilitarEndereco = () => {
        this.Endereco.setState({
            ...this.Endereco.state,
            address: {
                ...this.Endereco.state.address,
                UF: null,
            },
            disabled: {
                city: false,
                UF: false,
            },
            optionsCity: []
        })
    }

    validarINEP = async () => {
        if(!this.state.school.mecCode && !this.props.match.params.id)
            this.habilitarEndereco();

        else if(!await this.validarSeINEPExistente())
            await this.validarPrefixoINEPValido();
    }

   

    render() {
        const page = keycloak.authenticated ? this.state.school.id ? 'Editar Escola' : 'Adicionar Escola' : 'Adicionar Escola Nova';
        const links = keycloak.authenticated ? [{ path: '/admin/escola', name: 'Escolas' }] : [{ path: '/inscricao', name: 'Inscrição' }];

        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>

                <Title>{page}</Title>

                {this.state.loading ?
                    <Loading />
                :
                    <>
                        {!this.state.school.id &&
                            <>
                                <Grid container spacing={3}>
                                    <Grid item sm={12} lg={4}>
                                        <FormControl component="div" style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                                            <FormLabel style={{ alignSelf: 'center', marginRight: 20 }} required component="legend" >A Escola Possui Código MEC/INEP?</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-label="A Escola Possui Código MEC/INEP?"
                                                name="possuiMecCode"
                                                value={this.state.school.possuiMecCode}
                                                onChange={this.handleChange}
                                            >
                                                <FormControlLabel style={{ marginRight: 30 }} value="true" control={<Radio />} label="Sim" />
                                                <FormControlLabel style={{ marginRight: 30 }} value="false" control={<Radio />} label="Não" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {this.state.school.possuiMecCode === "false" ?
                                    <Grid container spacing={3}>
                                        <Grid item sm={12} lg={6}>
                                            <Alert severity="warning">
                                                <Typography variant="body2">
                                                    Antes de criar uma escola nova, verifique se a escola realmente <b>NÃO POSSUI</b> Código <b>MEC/INEP</b>.
                                                </Typography>
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                : this.state.school.possuiMecCode === "true" &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={12} lg={6}>
                                            <Alert severity="info">
                                                <Typography variant="body2">
                                                    Digite o Código <b>MEC/INEP</b> correto, pois este será o código utilizado dentro de todo o processo da Olimpíada Mirim.
                                                </Typography>
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                }
                            </>
                        }

                        <Grid container spacing={3}>
                            {this.state.school.possuiMecCode === "true" &&
                                <Grid item lg={2}>
                                    <Text
                                        required
                                        name="mecCode"
                                        label="INEP"
                                        value={this.state.school.mecCode}
                                        error={this.state.errors.mecCode}
                                        onChange={this.handleChange}
                                        onBlur={this.validarINEP}
                                        helperText={this.state.helpers.mecCode}
                                        disabled={this.state.school.id && (this.state.school.isInscrita || (!this.state.school.isInscrita && !this.state.school.id.includes("999", 0))) ? true : false}
                                    />
                                </Grid>
                            }
                    
                            <Grid item sm={10} lg={5}>
                                <Text
                                    required
                                    name="name"
                                    label="Nome da Escola"
                                    value={this.state.school.name}
                                    error={this.state.errors.name}
                                    onChange={this.handleChange}
                                    helperText={this.state.helpers.name}
                                />
                            </Grid>
                        </Grid>

                        <Email parentRef={this.setEmail} name="Email da Escola" />
                        <Endereco parentRef={this.setEndereco} />
                        <DependenciaAdministrativa onChange={this.dependenciaAdministrativaComponentChanged} parentRef={this.setDependenciaAdministrativa} withFederal={true} create={!this.state.school.id} />

                        <Telefone parentRef={this.setTelefone} />

                        <EscolaStatus possuiConvenio={this.state.school.possuiConvenio} parentRef={this.setEscolaStatus} escola={this.state.school.id} />

                        <ButtonsForm
                            onClick={this.handleSubmit}
                            onBack={() => this.handleBack()}
                            idFocus={`saveButton`}
                            loading={this.state.loadingButtonSave}
                        />
                    </>
                }
            </>
        )
    }
};

export default withRouter(EscolaForm);