import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterDataFimInscricaoEDataFimPagamento } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { ReciboService } from '../../services/Inscricao';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Loading/Loading';
import { ButtonSecondary } from '../../components/Button/Button';

import DadosEscola from '../../forms/Recibo/DadosEscola';
import DadosResponsavel from '../../forms/Recibo/DadosResponsavel';
import DadosEndereco from '../../forms/Recibo/DadosEndereco';
import DadosInscricao from '../../forms/Recibo/DadosInscricao';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __RECIBO_EXPORTAR } from '../../security/RoleConfiguration';

import { locationEscolaParse } from '../../helper/ParseStateHelper';

import { MenuContext } from '../../context/MenuContext';

import { Layout } from '../../layouts/private/Private';
import Alertas from '../Dashboard/Alertas';

export class Escola extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      setOpen: this.setOpen,
      escola: {
        cdEscola: '',
        nome: '',
        dependenciaAdministrativa: '',
        cnpj: '',
        possuiConvenio: '',
        dependenciaConvenio: '',
        dependenciaAlunoConvenio: '',
        email: '',
        telefones: '',
      },
      endereco: {
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        municipio: '',
        uf: '',
      },
      responsavel: {
        email: '',
        cpf: '',
        nome: '',
        telefones: ''
      },
      inscricao: {
        mirim1QtdAlunosPrevistos: '',
        mirim2QtdAlunosPrevistos: '',
        mirim1QtdMaximaAlunosClassificaveis: '',
        mirim2QtdMaximaAlunosClassificaveis: '',
      },
      enderecoEntrega: {
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        municipio: '',
        uf: '',
      }
    }
  }

  // CONTEXTO DE MENU
  static contextType = MenuContext;

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;

  // EXPORTAR PDF
  handleExportChange = async () => {
    const { open, setOpen } = this.context;
    if (open) await setOpen(!open);
    window.print();
  }

  async componentDidMount() {
    this.props.loadContext();

    const f = locationEscolaParse(this.props.location);
    const cdEscola = !isNaN(this.props.match.params.id) ? this.props.match.params.id : f.find(f => f.field === 'id').value;

    ReciboService.receipt(cdEscola)
      .then(res => {
        this.setState({
          escola: {
            cdEscola: res.data.cdMecEscola,
            nome: res.data.nmEscola,
            dependenciaAdministrativa: res.data.nmDependenciaAdministrativa,
            cnpj: res.data.cnpj,
            possuiConvenio: res.data.possuiConvenio,
            dependenciaConvenio: res.data.dependenciaConvenio,
            dependenciaAlunoConvenio: res.data.dependenciaAlunoConvenio,
            email: res.data.nmEmail,
            telefones: res.data.nmTelefonesFormatados,
          },
          endereco: {
            cep: res.data.endereco.nmCep,
            endereco: res.data.endereco.nmEndereco,
            numero: res.data.endereco.nmNumero,
            complemento: res.data.endereco.nmComplemento,
            bairro: res.data.endereco.nmBairro,
            municipio: res.data.endereco.municipio.nmMunicipio,
            uf: res.data.endereco.municipio.uf.nmUf,
          },
          responsavel: {
            email: res.data.responsavel.nmEmail,
            cpf: res.data.responsavel.nmCpf,
            nome: res.data.responsavel.nmNome,
            cargo: res.data.responsavel.nmCargo,
            telefones: res.data.responsavel.nmTelefonesFormatados
          },
          inscricao: {
            modalidade: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts) ? res.data.inscricao.modalidadeInscricao : res.data.inscricao.modalidade,
            mirim1QtdAlunosPrevistos: res.data.inscricao.mirim1.qtdAlunosPrevistos,
            mirim2QtdAlunosPrevistos: res.data.inscricao.mirim2.qtdAlunosPrevistos,
            mirim1QtdMaximaAlunosClassificaveis: res.data.inscricao.mirim1.qtdMaximaAlunosClassificaveis,
            mirim2QtdMaximaAlunosClassificaveis: res.data.inscricao.mirim2.qtdMaximaAlunosClassificaveis,
            valorPrevisto: res.data.inscricao.valorPrevisto !== "0.00" ? res.data.inscricao.valorPrevisto : null,
          },
          enderecoEntrega: {
            cep: res.data.enderecoEntrega.nmCep,
            endereco: res.data.enderecoEntrega.nmEndereco,
            numero: res.data.enderecoEntrega.nmNumero,
            complemento: res.data.enderecoEntrega.nmComplemento,
            bairro: res.data.enderecoEntrega.nmBairro,
            municipio: res.data.enderecoEntrega.municipio.nmMunicipio,
            uf: res.data.enderecoEntrega.municipio.uf.nmUf,
          }
        })
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => this.setState({ loading: false, }));
  }

  render () {
    const pageName = 'Recibo de Inscrição';
    const links = [];

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid className="no-print" container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        { this.state.loading ? 
          <Loading />
        :
          <>
            <Alertas mec={this.state.escola.cdEscola} />
            <DadosResponsavel responsavel={this.state.responsavel} />
            <DadosEscola escola={this.state.escola} />
            <DadosEndereco endereco={this.state.endereco} name="Endereço da Escola"/>
            <DadosInscricao inscricao={this.state.inscricao} />
            <DadosEndereco endereco={this.state.enderecoEntrega} name="Endereço de Entrega de Material da 2ª Fase" />
          </>
        }
        
        <Grid className="no-print" container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>
          <Grid item sm={12} lg={12}>
            <AuthorizedElement roles={[__RECIBO_EXPORTAR]}>
              <ButtonExport 
                title="Exportar PDF"
                onClick={this.handleExportChange} 
              />
            </AuthorizedElement>
            <ButtonSecondary
              startIcon={<ArrowBackIcon />}
              style={{ margin: 4 }}
              onClick={this.props.history.goBack}
              name={"Voltar"}
            />
          </Grid>   
        </Grid>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Escola));