import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import EscolaService from '../../services/Escola';

import Filters from '../../components/Filters/Filters';
import Text from '../../components/Inputs/Text/Text';
import SelectOption from '../../components/Inputs/Select/Select';
import Boolean from '../../components/Inputs/Boolean/Boolean';

import Endereco from '../Endereco/EnderecoFilter';
import DependenciaAdministrativa from '../DependenciaAdministrativa/DependenciaAdministrativaFilter';
import ResponsavelInscricao from '../ResponsavelInscricao/ResponsavelInscricaoFilter';
import { EscolaFilterFields as Escola } from '../Escola/EscolaFilterFields';

import { optionsRange } from '../../variables/Enums/Range';
import { 
    optionsOrigem, 
    optionsStatus, 
    optionsNivel, 
    optionsTipoOcorrencia, 
    optionsConvenio, 
    optionsInscricao,
    optionsModalidade, 
    optionsStatusPremiacao
} from '../../variables/Enums/Escola';

import { download } from '../../helper/FileHelper';
import { removePagingAndSorting } from '../../helper/PaginationHelper';

export class EscolaFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                inscrita: '',
                origem: '',
                status: '',
                tipoOcorrencia: '',
                convenio: '',
                nivelAlunosPrevistos: '',
                alunosPrevistos: '',
                qntdAlunosPrevistos: '',
                nivelAlunosParticipantes: '',
                alunosParticipantes: '',
                qntdAlunosParticipantes: '',
                nivelAlunosClassificaveis: '',
                alunosClassificaveis: '',
                qntdAlunosClassificaveis: '',
                objetoPostal: '',
                comAlgumaNotaLancadaNaFase2: '',
                modalidade: '',
                statusPremiacao: '',
                finalizouLancamentoNotas: '',
                comObjetoPostalMedalhas: '',
            },
            exportPlan: false,
            count: 0,
        }
    }  

    // INPUTS
    handleChange = e => {
        this.setState({
            filters: {...this.state.filters,  [e.target.name]: e.target.value }
        });
    };

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES TIPO FORM
    setEndereco = form => this.Endereco = form;
    setDependenciaAdministrativa = form => this.DependenciaAdministrativa = form;
    setResponsavelInscricao = form => this.ResponsavelInscricao = form;
    setEscola = form => this.Escola = form;

    // APLICAR PERSISTENCIA
    handleFilterPersistence = (filters) => {

        filters = removePagingAndSorting(filters);

        this.setState({
            filters: {                
                inscrita: filters.find(f =>  f.field === 'inscrita') ? filters.find(f =>  f.field === 'inscrita').value : "",
                origem: filters.find(f =>  f.field === 'inNova') ? filters.find(f =>  f.field === 'inNova').value : "",
                status: filters.find(f =>  f.field === 'nmStatus') ? filters.find(f =>  f.field === 'nmStatus').value : "",
                tipoOcorrencia: filters.find(f =>  f.field === 'tipoOcorrencia') ? filters.find(f =>  f.field === 'tipoOcorrencia').value : "",
                convenio: filters.find(f =>  f.field === 'convenio') ? filters.find(f =>  f.field === 'convenio').value : "",
                nivelAlunosPrevistos : filters.find(f =>  f.field === 'nivelAlunosPrevistos') ? filters.find(f =>  f.field === 'nivelAlunosPrevistos').value : "",
                alunosPrevistos: filters.find(f =>  f.field === 'operadorAlunosPrevistos') ? filters.find(f =>  f.field === 'operadorAlunosPrevistos').value : "",
                qntdAlunosPrevistos: filters.find(f =>  f.field === 'qtdAlunosPrevistos') ? filters.find(f =>  f.field === 'qtdAlunosPrevistos').value : "",
                nivelAlunosParticipantes: filters.find(f =>  f.field === 'nivelAlunosParticipantes') ? filters.find(f =>  f.field === 'nivelAlunosParticipantes').value : "",
                alunosParticipantes: filters.find(f =>  f.field === 'operadorAlunosParticipantes') ? filters.find(f =>  f.field === 'operadorAlunosParticipantes').value : "",
                qntdAlunosParticipantes: filters.find(f =>  f.field === 'qtdAlunosParticipantes') ? filters.find(f =>  f.field === 'qtdAlunosParticipantes').value : "",
                nivelAlunosClassificaveis: filters.find(f =>  f.field === 'nivelAlunosClassificaveis') ? filters.find(f =>  f.field === 'nivelAlunosClassificaveis').value : "",
                alunosClassificaveis: filters.find(f =>  f.field === 'operadorAlunosClassificaveis') ? filters.find(f =>  f.field === 'operadorAlunosClassificaveis').value : "",
                qntdAlunosClassificaveis: filters.find(f =>  f.field === 'qtdAlunosClassificaveis') ? filters.find(f =>  f.field === 'qtdAlunosClassificaveis').value : "",
                objetoPostal: filters.find(f =>  f.field === 'objetoPostal') ? filters.find(f =>  f.field === 'objetoPostal').value : "",
                comAlgumaNotaLancadaNaFase2: filters.find(f =>  f.field === 'comAlgumaNotaLancadaNaFase2') ? filters.find(f =>  f.field === 'comAlgumaNotaLancadaNaFase2').value : "",
                modalidade: filters.find(f =>  f.field === 'modalidade') ? filters.find(f =>  f.field === 'modalidade').value : "",
                statusPremiacao: filters.find(f =>  f.field === 'statusPremiacao') ? filters.find(f =>  f.field === 'statusPremiacao').value : "",
                finalizouLancamentoNotas: filters.find(f =>  f.field === 'finalizouLancamentoNotas') ? filters.find(f =>  f.field === 'finalizouLancamentoNotas').value : "",
                comObjetoPostalMedalhas: filters.find(f =>  f.field === 'comObjetoPostalMedalhas') ? filters.find(f =>  f.field === 'comObjetoPostalMedalhas').value : "",
            },
            count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
        })

        this.ResponsavelInscricao.setState({
            filters: {
                nameResponsible: filters.find(f =>  f.field === 'nmResponsavel') ? filters.find(f =>  f.field === 'nmResponsavel').value : "",
                emailResponsable: filters.find(f =>  f.field === 'nmEmailResponsavel') ? filters.find(f =>  f.field === 'nmEmailResponsavel').value : "",
            }
        })

        this.Escola.setState({
            filters: {
                nameSchool: filters.find(f =>  f.field === 'nmEscola') ? filters.find(f =>  f.field === 'nmEscola').value : "",
                emailSchool: filters.find(f =>  f.field === 'nmEmail') ? filters.find(f =>  f.field === 'nmEmail').value : "",
                inepSchool: filters.find(f =>  f.field === 'cdEscola') ? filters.find(f =>  f.field === 'cdEscola').value : "",
            }
        })

        this.DependenciaAdministrativa.setState({
            filters: {
                adminDep: filters.find(f =>  f.field === 'nmDependenciaAdministrativa') ? filters.find(f =>  f.field === 'nmDependenciaAdministrativa').value : "",
            }
        })
              
        this.Endereco.setState({
            filters: {
                UF: filters.find(f =>  f.field === 'cdUf') ? filters.find(f =>  f.field === 'cdUf').value : "",
                city: filters.find(f =>  f.field === 'cdMunicipio') ? filters.find(f =>  f.field === 'cdMunicipio').value : "",
                region: filters.find(f =>  f.field === 'cdRegiao') ? filters.find(f =>  f.field === 'cdRegiao').value : "",
            }
        })        
    }

    // FILTROS
    handleFilterApply = () => {
        const f = [];
        const { filters, exportPlan } = this.state;
        const filtersDepAdmin = this.DependenciaAdministrativa.state.filters;
        const filtersRespInscricao = this.ResponsavelInscricao.state.filters;
        const filtersEscola = this.Escola.state.filters;
        
        if (filters.inscrita) f.push({ field: 'inscrita', value: filters.inscrita })        
        if (filters.origem !== '') f.push({ field: 'inNova', value: filters.origem })
        if (filters.status) f.push({ field: 'nmStatus', value: filters.status })
        if (filters.tipoOcorrencia) f.push({ field: 'tipoOcorrencia', value: filters.tipoOcorrencia })
        if (filters.convenio) f.push({ field: 'convenio', value: filters.convenio })
        
        if (filters.objetoPostal) f.push({ field: 'objetoPostal', value: filters.objetoPostal })
        if (filters.comAlgumaNotaLancadaNaFase2 !== '') f.push({ field: 'comAlgumaNotaLancadaNaFase2', value: filters.comAlgumaNotaLancadaNaFase2 })
        if (filters.modalidade !== '') f.push({ field: 'modalidade', value: filters.modalidade })

        if (filters.statusPremiacao !== '') f.push({ field: 'statusPremiacao', value: filters.statusPremiacao })
        if (filters.finalizouLancamentoNotas !== '') f.push({ field: 'finalizouLancamentoNotas', value: filters.finalizouLancamentoNotas })
        if (filters.comObjetoPostalMedalhas !== '') f.push({ field: 'comObjetoPostalMedalhas', value: filters.comObjetoPostalMedalhas })
        
        if (filters.alunosParticipantes && filters.qntdAlunosParticipantes) {
            f.push({ field: 'operadorAlunosParticipantes', value: filters.alunosParticipantes })
            f.push({ field: 'qtdAlunosParticipantes', value: filters.qntdAlunosParticipantes })
            
            if (filters.nivelAlunosParticipantes)
            f.push({ field: 'nivelAlunosParticipantes', value: filters.nivelAlunosParticipantes })
        }

        if (filters.alunosPrevistos && filters.qntdAlunosPrevistos) {
            f.push({ field: 'operadorAlunosPrevistos', value: filters.alunosPrevistos })
            f.push({ field: 'qtdAlunosPrevistos', value: filters.qntdAlunosPrevistos })
            
            
            if (filters.nivelAlunosPrevistos)
            f.push({ field: 'nivelAlunosPrevistos', value: filters.nivelAlunosPrevistos })
        }
        
        if (filters.alunosClassificaveis && filters.qntdAlunosClassificaveis) {
            f.push({ field: 'operadorAlunosClassificaveis', value: filters.alunosClassificaveis })
            f.push({ field: 'qtdAlunosClassificaveis', value: filters.qntdAlunosClassificaveis })
            
            
            if (filters.nivelAlunosClassificaveis)
            f.push({ field: 'nivelAlunosClassificaveis', value: filters.nivelAlunosClassificaveis })
        }
        
        if (filtersDepAdmin.adminDep) f.push({ field: 'nmDependenciaAdministrativa', value: filtersDepAdmin.adminDep })
        
        if (filtersRespInscricao.nameResponsible) f.push({ field: 'nmResponsavel', value: filtersRespInscricao.nameResponsible })
        if (filtersRespInscricao.emailResponsible) f.push({ field: 'nmEmailResponsavel', value: filtersRespInscricao.emailResponsible })
        
        if (filtersEscola.nameSchool) f.push({ field: 'nmEscola', value: filtersEscola.nameSchool })
        if (filtersEscola.emailSchool) f.push({ field: 'nmEmail', value: filtersEscola.emailSchool })
        if (filtersEscola.inepSchool) f.push({ field: 'cdEscola', value: filtersEscola.inepSchool })
        
        const filtersEndereco = this.Endereco.state.filters;
        if (filtersEndereco.UF) f.push({ field: 'cdUf', value: filtersEndereco.UF })
        if (filtersEndereco.city) f.push({ field: 'cdMunicipio', value: filtersEndereco.city })
        if (filtersEndereco.region) f.push({ field: 'cdRegiao', value: filtersEndereco.region })

        if (exportPlan) {
            f.push({ field: 'exportarPlanilha', value: exportPlan });

            EscolaService.export(f)
                .then(res => download(res))
                .finally(() => this.setState({ exportPlan: false }))
        } else {
            this.props.handleFilterChange(f);
        };        
    }

    // LIMPAR FILTROS
    handleFilterClear = () => {
        this.setState({
            filters: {
                inscrita: '',
                origem: '',
                status: '',
                tipoOcorrencia: '',
                convenio: '',
                nivelAlunosPrevistos: '',
                alunosPrevistos: '',
                qntdAlunosPrevistos: '',
                nivelAlunosParticipantes: '',
                alunosParticipantes: '',
                qntdAlunosParticipantes: '',
                nivelAlunosClassificaveis: '',
                alunosClassificaveis: '',
                qntdAlunosClassificaveis: '',
                objetoPostal: '',
                comAlgumaNotaLancadaNaFase2: '',
                modalidade: '',
                statusPremiacao: '',
                finalizouLancamentoNotas: '',
                comObjetoPostalMedalhas: '',
            },
            exportPlan: false,
            count: 0,
        })

        this.DependenciaAdministrativa.setState({
            filters: {
                adminDep: '',
            }
        })

        this.ResponsavelInscricao.setState({
            filters: {
                nameResponsible: '',
                emailResponsable: '',
            }
        })

        this.Escola.setState({
            filters: {
                nameSchool: '',
                emailSchool: '',
                inepSchool: '',  
            }
        })

        this.Endereco.limparBuscas();

        this.props.handleFilterChange([], false);
    }

    // CONTAGEM DE FILTROS ATIVOS
    handleCountFilters = () => this.state.count;

    render () {
        return (
            <Filters
                handleFilter={this.handleFilterApply}
                handleFilterClear={this.handleFilterClear}
                countFilters={this.handleCountFilters}
            >
                <Grid item container spacing={3}>
                    <ResponsavelInscricao
                        parentRef={this.setResponsavelInscricao}
                    />
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Status da Inscrição"
                            name='inscrita'
                            value={this.state.filters.inscrita}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsInscricao}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Modalidade"
                            name='modalidade'
                            value={this.state.filters.modalidade}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsModalidade}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Escola
                        parentRef={this.setEscola}
                    />        
                    <DependenciaAdministrativa 
                        parentRef={this.setDependenciaAdministrativa} 
                        withFederal={true}
                        withPublicGroup={true}
                    />
                    <Grid item sm={12} lg={1}>
                        <SelectOption
                            label="Convênio"
                            name='convenio'
                            value={this.state.filters.convenio}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsConvenio}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Endereco 
                        parentRef={this.setEndereco}
                        withRegion={true}
                    />

                    <Grid item sm={12} lg={1}></Grid>

                    <Grid item sm={12} lg={1}>
                        <SelectOption
                            label="Status"
                            name='status'
                            value={this.state.filters.status}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsStatus}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Tipo de Ocorrência"
                            name='tipoOcorrencia'
                            value={this.state.filters.tipoOcorrencia}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsTipoOcorrencia}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Origem"
                            name='origem'
                            value={this.state.filters.origem}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsOrigem}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Nivel de Alunos Previstos"
                            name='nivelAlunosPrevistos'
                            value={this.state.filters.nivelAlunosPrevistos}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsNivel}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Critério"
                            name='alunosPrevistos'
                            value={this.state.filters.alunosPrevistos}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsRange}
                        />
                    </Grid>
                    <Grid item sm={12} lg={1}>
                        <Text
                            label="Quantidade"
                            name='qntdAlunosPrevistos'
                            value={this.state.filters.qntdAlunosPrevistos}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>

                    <Grid item sm={12} lg={2}></Grid>

                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Nivel de Alunos Participantes"
                            name='nivelAlunosParticipantes'
                            value={this.state.filters.nivelAlunosParticipantes}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsNivel}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Critério"
                            name='alunosParticipantes'
                            value={this.state.filters.alunosParticipantes}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsRange}
                        />
                    </Grid>
                    <Grid item sm={12} lg={1}>
                        <Text
                            label="Quantidade"
                            name='qntdAlunosParticipantes'
                            value={this.state.filters.qntdAlunosParticipantes}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Nivel de Alunos Classificáveis"
                            name='nivelAlunosClassificaveis'
                            value={this.state.filters.nivelAlunosClassificaveis}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsNivel}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Critério"
                            name='alunosClassificaveis'
                            value={this.state.filters.alunosClassificaveis}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsRange}
                        />
                    </Grid>
                    <Grid item sm={12} lg={1}>
                        <Text
                            label="Quantidade"
                            name='qntdAlunosClassificaveis'
                            value={this.state.filters.qntdAlunosClassificaveis}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                </Grid>

                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={2}>
                        <Text
                            label="Objeto Postal"
                            name='objetoPostal'
                            value={this.state.filters.objetoPostal}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <Boolean
                            label="Com Objeto Postal de Medalhas para Premiação?"
                            name='comObjetoPostalMedalhas'
                            value={this.state.filters.comObjetoPostalMedalhas}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <Boolean
                            label="Com Alguma Nota Lançada na Fase 2"
                            name='comAlgumaNotaLancadaNaFase2'
                            value={this.state.filters.comAlgumaNotaLancadaNaFase2}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>

                    <Grid item sm={12} lg={1}></Grid>

                    <Grid item sm={12} lg={2}>
                        <Boolean
                            label="Finalizou Lançamento de Notas?"
                            name='finalizouLancamentoNotas'
                            value={this.state.filters.finalizouLancamentoNotas}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Status de Premiação"
                            name='statusPremiacao'
                            value={this.state.filters.statusPremiacao}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsStatusPremiacao}
                        />
                    </Grid>
                </Grid>

            </Filters>
        )
    }
}

export default EscolaFilter;