import React, { Fragment } from 'react';
import clsx from  'clsx';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';

import Tooltip from '@material-ui/core/Tooltip';

import useStyles from './Style';
import List from './List';

import menuList from '../../variables/Links/Menu';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __ADMINISTRADOR, __CENTRAL } from '../../security/RoleConfiguration';

const DrawerLeft = (props) => {
  const classes = useStyles();

  let listItems = menuList.map( (menu, key) => {     
    return (
      <Fragment key={key} >
        <List items={menu.items} category={menu.category} />
      </Fragment>
    )
  });  

  return (
    <Drawer
      className={clsx(classes.drawer, 'no-print')}
      variant="persistent"
      anchor="left"
      open={props.open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <AuthorizedElement roles={[__ADMINISTRADOR, __CENTRAL]}>
          <IconButton onClick={() => window.open(`${process.env.REACT_APP_PREV_EDITION_URL}`, '_blank')}>
            <Tooltip title="Ir para Edição Anterior" aria-label="Ir para Edição Anterior">
              <ReplayOutlinedIcon />
            </Tooltip>
          </IconButton>
        </AuthorizedElement>
        <IconButton onClick={props.click}>
          <Tooltip title="Ocultar Menu" aria-label="Ocultar Menu">
            <ChevronLeftIcon />
          </Tooltip>
        </IconButton>
      </div>
      <Divider />

      { listItems }
    </Drawer>
  );
}

export default DrawerLeft;