import React from 'react';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Participantes = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-02" className={classes.title}>
               2. DOS PARTICIPANTES
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>2.1</span> Estão aptos a participar da 2ª Olimpíada Mirim – OBMEP, alunos(as) regularmente matriculados(as) no <span className={clsx(classes.bolder, classes.underline)}>2º, 3º, 4º ou 5º ano do Ensino Fundamental</span>, 
                em <span className={clsx(classes.bolder, classes.underline)}>escolas públicas</span> municipais, estaduais e federais e em <span className={clsx(classes.bolder, classes.underline)}>escolas privadas</span> localizadas no território brasileiro.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>2.2</span> Alunos(as) matriculados(as) em outras modalidades, como EJA, estão aptos a participar desde que sua série escolar corresponda ao 2º, 3º, 4º ou 5º ano do Ensino Fundamental.
            </Typography>
        </>
    )
}

export default Participantes;