import React from 'react'

import Grid from '@material-ui/core/Grid';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { green } from '@material-ui/core/colors';

import Title from '../../components/Title/Title';
import { LinkTargetBlank } from '../../components/Link/Link';

import useStyles from './Style';

export const TerceiroNivelA = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={12} >
      <Grid item xs={12} sm={12} lg={6} xl={5} >
        <Title variant="h6">
          PROVAS
        </Title>

        <div className={classes.file}>
          <InsertDriveFileOutlinedIcon style={{ color: green[800] }} fontSize="large" />
          <LinkTargetBlank link="https://drive.google.com/file/d/1Clj_jeVBzSShbxrWTHB8DxDvegwKWtBx/view">1ª OBMEP - NIVEL A</LinkTargetBlank>
        </div>
        <div className={classes.file}>
          <InsertDriveFileOutlinedIcon style={{ color: green[800] }} fontSize="large" />
          <LinkTargetBlank link="https://drive.google.com/file/d/1gj5_LQ-_4CH9PfT66sQhtwPsbVjmwmXi/view">2ª OBMEP - NIVEL A</LinkTargetBlank>
        </div>
        <div className={classes.file}>
          <InsertDriveFileOutlinedIcon style={{ color: green[800] }} fontSize="large" />
          <LinkTargetBlank link="https://drive.google.com/file/d/1GmV6Al8jEZPWpavh8vtztY5CI5caL3fz/view">3ª OBMEP - NIVEL A</LinkTargetBlank>
        </div>
      </Grid>
      
      <Grid item xs={12} sm={12} lg={6} xl={5} >
        <Title variant="h6">
          SOLUÇÕES
        </Title>
        <div className={classes.file}>
          <InsertDriveFileOutlinedIcon style={{ color: green[800] }} fontSize="large" />
          <LinkTargetBlank link="https://drive.google.com/file/d/198bKB4oZ8atJ4DoPDtY9TCZ3poecAyG1/view">1ª OBMEP - NIVEL A</LinkTargetBlank>
        </div>
        <div className={classes.file}>
          <InsertDriveFileOutlinedIcon style={{ color: green[600] }} fontSize="large" />
          <LinkTargetBlank link="https://drive.google.com/file/d/1636hs3K9m5kjz2ExCXzsMjZ9REgxhTSS/view">2ª OBMEP - NIVEL A</LinkTargetBlank>
        </div>
        <div className={classes.file}>
          <InsertDriveFileOutlinedIcon style={{ color: green[600] }} fontSize="large" />
          <LinkTargetBlank link="https://drive.google.com/file/d/1_Q6FqdOzKVx0tYicM8BTjnpK7k2CqkUK/view">3ª OBMEP - NIVEL A</LinkTargetBlank>
        </div>
      </Grid>
    </Grid>
  )
}

export default TerceiroNivelA;
