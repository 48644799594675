import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { blue, green, red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import LoopIcon from '@material-ui/icons/Loop';
import ErrorIcon from '@material-ui/icons/Error';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';

import EmailService from '../../services/Email';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from "../../components/Toast/Toast";
import { ButtonSecondary } from '../../components/Button/Button';

import { ButtonSave } from '../../forms/Buttons/ButtonsForm';
import DialogConfirm from '../../forms/Dialog/DialogConfirm';
import EmailViewer from '../../forms/Email/EmailViewer';

import { patternTypeMail } from '../../variables/Enums/Email';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import { __EMAIL_ENVIAR } from '../../security/RoleConfiguration';

import { Layout } from '../../layouts/private/Private';
import Loading from '../../components/Loading/Loading';

const useStyles = makeStyles((theme) => (
    {
        error: {
            backgroundColor: red[400],
            color: "#fff",
        },
        info: {
            backgroundColor: blue[400],
            color: "#fff",
        },
        success: {
            backgroundColor: green[400],
            color: "#fff",
        },
        icon: {
            color: "#fff",
        }
    }
));

const ChipStatus = ({ status }) => {
    const classes = useStyles();

    return (
        <Chip
            size="small"
            icon={status === 'SUCESSO' ? <DoneIcon className={classes.icon} /> : status === 'FALHA' ? <ErrorIcon className={classes.icon} /> : status === 'AGUARDANDO' ? <LoopIcon className={classes.icon} /> : <BlockIcon className={classes.icon} />}
            label={<b>{status === 'SUCESSO' ? 'Sucesso' : status === 'FALHA' ? 'Falha' : status === 'AGUARDANDO' ? 'Aguardando' : 'Descartado'}</b>}
            className={status === 'SUCESSO' ? classes.success : status === 'FALHA' ? classes.error : status === 'AGUARDANDO' ? classes.info : null}
        />
    )
}

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mail: {
                id: !isNaN(this.props.match.params.id) ? this.props.match.params.id : '',
                subject: null,
                message: null,
                status: null,
                type: null,
                to: null,
                escola: {
                    cdEscola: '',
                    nmEscola: '',
                },
                resend: false,
            },
            loading: false,
        }
    }

    // FECHAR TOAST
    setToast = t => this.Toast = t;
    setDialogConfirm = d => this.DialogConfirm = d;

    // DIALOG DE CONFIRMAÇÃO DE REENVIO
    openDialogConfirm = () => {
        this.DialogConfirm.setState({
            dialog: {
                open: true,
                title: `Deseja Reenviar o Email de ${patternTypeMail(this.state.mail.type)} para a Escola ${this.state.mail.escola.nmEscola} (${this.state.mail.escola.cdEscola}) ?`,
                text: `O Envio do Email não poderá ser cancelado depois dessa ação.`,
                id: this.state.mail.id,
                loading: false,
            }
        });
    }

    // REENVIAR EMAIL
    handleSendMail = (id) => {
        this.DialogConfirm.loading();

        const mail = {
            para: this.state.mail.to,
            tipoEmail: this.state.mail.type,
            assunto: this.state.mail.subject,
            mensagem: this.state.mail.message,
            escola: this.state.mail.escola.cdEscola,
        }

        EmailService.send(mail)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "O Reenvio de Email foi Configurado e em Instantes será Realizado",
                        type: "success",
                        open: true
                    }
                });
            })
            .catch(e => {
                this.Toast.setState({
                    message: {
                        message: "Ocorreu um Problema ao Configurar o Reenvio do Email. Tente Novamente Mais Tarde",
                        type: "error",
                        open: true
                    }
                })
            })
            .finally(() => this.DialogConfirm.close());
    }

    componentDidMount() {
        if (this.state.mail.id) {
            const id = this.state.mail.id;

            this.setState({ loading: true });

            EmailService.find(id)
                .then(res => {
                    this.setState({
                        mail: {
                            id: res.data.id,
                            subject: res.data.titulo,
                            message: res.data.corpo,
                            status: res.data.statusEnvio,
                            type: res.data.processo,
                            to: res.data.para,
                            escola: {
                                cdEscola: res.data.escola ? res.data.escola.cdMecEscola : '--',
                                nmEscola: res.data.escola ? res.data.escola.nmEscola : '--',
                            },
                            resend: res.data.escola ? true : false,
                        }
                    });
                })
                .catch(error => {
                    const e = errors(error);
                    this.Toast.setState({
                        message: {
                            message: e.message,
                            type: e.type,
                            open: true
                        }
                    })
                })
                .finally(() => this.setState({ loading: false }));
        }
    }

    render() {
        const page = 'Detalhes de Email Enviado';
        const links = [
            {
                path: `/admin/emails`,
                name: 'Emails Enviados',
            }
        ];

        return (
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>

                <Title>{page}</Title>

                { this.state.loading ? 
                    <Loading /> 
                :   
                    <>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item sm={12} lg={12}>
                                <Paper elevation={1} style={{ padding: 24 }} >
                                    <Grid style={{ paddingBottom: 24 }}>
                                        <Typography variant="body2"><b>Escola:</b> {this.state.mail.escola.nmEscola} ({this.state.mail.escola.cdEscola})</Typography>
                                    </Grid>

                                    <Grid style={{ paddingBottom: 24 }}>
                                        <Typography variant="body2"><b>Para:</b> {this.state.mail.to}</Typography>
                                    </Grid>

                                    <Grid style={{ paddingBottom: 24 }}>
                                        <Typography variant="body2">
                                            <b>Status de Envio:  </b><ChipStatus status={this.state.mail.status} />
                                        </Typography>
                                    </Grid>

                                    <Grid>
                                        <Typography variant="body2"><b>Tipo de Email:</b> {patternTypeMail(this.state.mail.type)}</Typography>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <EmailViewer
                                subject={this.state.mail.subject}
                                message={this.state.mail.message}
                            />
                        </Grid>

                        <DialogConfirm parentRef={this.setDialogConfirm} handleConfirm={this.handleSendMail} />

                        <Grid container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>
                            { AuthorizedFunction([__EMAIL_ENVIAR]) && this.state.resend &&
                                <Grid item sm={12} lg={4}>
                                    <ButtonSave
                                        idFocus={`saveButton`}
                                        startIcon={<SendIcon />}
                                        onClick={this.openDialogConfirm}
                                        name="Reenviar Este Email"
                                    />
                                </Grid>
                            }
                            <Grid item sm={12} lg={12}>
                                <ButtonSecondary
                                    startIcon={<ArrowBackIcon />}
                                    style={{ margin: 4 }}
                                    onClick={this.props.history.goBack}
                                    name={"Voltar"}
                                />
                            </Grid>
                        </Grid>
                    </>
                }
            </Layout>
        )
    }
}

export default withRouter(Form);