import React from 'react';

import Typography from '@material-ui/core/Typography';
import Title from '../../components/Title/Title';

import useStyles from './Style';

const Cookies = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-4" variant="h6" className={classes.subtitle}>
                <span className={classes.item}>4.</span> COOKIES E DEMAIS INFORMAÇÕES COLETADAS ATRAVÉS DOS PORTAIS ELETRÔNICOS DO IMPA
            </Title>

            <Typography className={classes.text} variant="body2">
                Ao acessar os portais da OBMEP e de seus programas acadêmicos correlatos algumas informações podem ser coletadas automaticamente. Essas informações permitem o correto funcionamento do site,
                o aprimoramento da experiência de navegação do usuário, bem como a realização de estudos estatísticos sobre essa página.
            </Typography>
            <Typography className={classes.text} variant="body2">
                São informações que podem ser coletadas automaticamente em nosso portal:

                <ul className={classes.list}>
                    <li>
                        <span className={classes.underline}>Cookies estritamente necessários:</span><br />
                        Esses cookies permitem funcionalidades essenciais, tais como segurança, verificação de identidade e gestão de rede. Esses cookies não podem ser desativados
                        em nossos sistemas. Embora sejam necessários, você pode bloquear esses cookies diretamente no seu navegador, mas isso pode comprometer sua experiência e prejudicar o funcionamento do site;
                    </li><br />
                    <li>
                        <span className={classes.underline}>Cookies de terceiros (Google Analytics):</span><br />
                        São os cookies que permitem que nós e serviços de terceiros coletemos dados agregados para fins estatísticos sobre como nossos visitantes usam o
                        site. Esses cookies não contêm informações pessoais, como nomes e endereços de e-mail, e são usados somente para nos ajudar a melhorar sua experiência no site.
                    </li>
                </ul>
            </Typography>

            <Typography className={classes.text} variant="body2">
                É possível ajustar as preferências para a coleta de cookies a partir das configurações do navegador de internet utilizado para acessar o nosso portal, restringindo a coleta desses dados.
            </Typography>
        </>
    )
}

export default Cookies;