import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const DadosUtilizados = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-3" variant="h6" className={classes.subtitle}>
                <span className={classes.item}>3.</span> DADOS PESSOAIS UTILIZADOS PELO IMPA E FINALIDADES RELACIONADAS
            </Title>

            <Typography className={classes.text} variant="body2">
                O IMPA possui propósitos específicos para a coleta e utilização de dados pessoais no âmbito da realização da OBMEP. A seguir, apresentamos esses objetivos e as informações envolvidas.
            </Typography>

            <Typography className={classes.itemText} variant="body2">
                PARTICIPAÇÃO NA SEGUNDA FASE DA OBMEP E REALIZAÇÃO DE PREMIAÇÕES
            </Typography>

            <Typography className={classes.text} variant="body2">
                Para participação na segunda fase da OBMEP e obtenção de premiações, o IMPA necessitará ter acesso a dados pessoais para que as provas sejam aplicadas, corrigidas e, eventualmente, sejam oferecidos
                os respectivos prêmios aos participantes que se enquadrarem nos requisitos previstos no Regulamento.
            </Typography>
            <Typography className={classes.text} variant="body2">
                A seguir, apresenta-se a descrição dos dados pessoais utilizados pelo IMPA e as finalidades vinculadas:

                <Grid
                    container
                    align='center'
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item xs={12} sm={8} lg={6} xl={6} >
                        <TableContainer component="div">
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.bolder} width="40%" align="center">Dados Pessoais</TableCell>
                                        <TableCell className={classes.bolder} width="60%" align="center">Finalidades</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ verticalAlign: 'top' }}>
                                            <ul className={classes.list}>
                                                <li>Nome</li>
                                                <li>Data de nascimento</li>
                                                <li>CPF</li>
                                                <li>Sexo</li>
                                                <li>Raça</li>
                                                <li>E-mail</li>
                                                <li>Telefone</li>
                                                <li>Endereço</li>
                                            </ul>
                                        </TableCell>
                                        <TableCell>
                                            <ul className={classes.list}>
                                                <li>Nome: Identificar o aluno classificado para a 2ª Fase;</li>
                                                <li>Data de nascimento (informação não obrigatória): Facilitar a identificação de homônimos;</li>
                                                <li>CPF (informação não obrigatória): Facilitar a identificação de homônimos;</li>
                                                <li>Sexo (informação não obrigatória): gerar indicativos para pesquisas e para o aprimoramento do projeto;</li>
                                                <li>Raça (informação não obrigatória): gerar indicativos para pesquisas e para o aprimoramento do projeto;</li>
                                                <li>E-mail, telefone e endereço: dados para contato com os premiados para convite para as cerimônias de premiação;</li>
                                                <li>Realizar estudos estatísticos sobre a participação dos alunos.</li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>

            <Typography className={classes.itemText} variant="body2">
                INSCRIÇÃO E PARTICIPAÇÃO EM PROGRAMAS ACADÊMICOS:
            </Typography>

            <Typography className={classes.text} variant="body2">
                O IMPA oferece diversos programas acadêmicos, além de cursos e formações de professores. Para participar e se candidatar a uma vaga como aluno ou bolsista, é necessário se inscrever no processo seletivo
                de cada Programa, disponíveis nos Portais da OBMEP. Estarão aptos a participarem do processo seletivo os candidatos que se enquadrarem nos requisitos previstos no Regulamento de cada Programa.
            </Typography>
            <Typography className={classes.text} variant="body2">
                Caso seja classificado para a vaga, o candidato deverá fornecer ao IMPA informações adicionais para que possa ser admitido.
            </Typography>
            <Typography className={classes.text} variant="body2">
                A seguir, apresenta-se a descrição dos dados pessoais utilizados pelo IMPA e as finalidades vinculadas:

                <Grid
                    container
                    align='center'
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item xs={12} sm={8} lg={6} xl={6} >
                        <TableContainer component="div">
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.bolder} width="40%" align="center">Dados Pessoais</TableCell>
                                        <TableCell className={classes.bolder} width="60%" align="center">Finalidades</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <span className={classes.underline}>No momento da Inscrição:</span>
                                            <ul className={classes.list}>
                                                <li>Nome</li>
                                                <li>Sexo</li>
                                                <li>Nacionalidade</li>
                                                <li>Foto</li>
                                                <li>Data de nascimento</li>
                                                <li>RG ou Passaporte</li>
                                                <li>CPF</li>
                                                <li>Nome e CPF (Pai e Mãe)</li>
                                                <li>Telefone</li>
                                                <li>E-mail</li>
                                                <li>E-mail do Responsável legal</li>
                                                <li>Endereço</li>
                                                <li>Premiação OBMEP e ano</li>
                                                <li>Formação acadêmica</li>
                                                <li>Instituição do maior grau de titulação acadêmica</li>
                                                <li>Ano de conclusão do maior grau de titulação acadêmica</li>
                                                <li>Currículo Lattes</li>
                                            </ul>
                                            <br />

                                            <span className={classes.underline}>No momento da admissão:</span>
                                            <ul className={classes.list}>
                                                <li>RG ou passaporte</li>
                                                <li>CPF</li>
                                                <li>Comprovante de Residência</li>
                                                <li>Comprovante escolar</li>
                                                <li>Foto</li>
                                                <li>Diploma do curso de maior grau de titulação acadêmica</li>
                                                <li>Visto</li>
                                                <li>Dados bancários</li>
                                                <li>Assinatura Termo de Consentimento e Autorização de Imagem</li>
                                            </ul>
                                        </TableCell>
                                        <TableCell style={{ verticalAlign: 'top' }}>
                                            <ul className={classes.list}>
                                                <li>Identificar o usuário e confirmar o preenchimento dos requisitos para a concessão da bolsa;</li>
                                                <li>Permitir a inscrição e a admissão como participante em programas de responsabilidade do IMPA;</li>
                                                <li>Viabilizar o envio de comunicados diversos ao usuário;</li>
                                                <li>Permitir acesso aos sistemas e às ferramentas disponibilizados pelo IMPA;</li>
                                                <li>Permitir o exercício regular de direitos assegurados ao IMPA;</li>
                                                <li>Realizar estudos estatísticos sobre a participação de alunos, professores e coordenadores nos cursos oferecidos pelo IMPA;</li>
                                                <li>Incluir dados em relatórios internos ou quando solicitado por parceiros públicos ou privados.</li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>
        </>
    )
}

export default DadosUtilizados;