import React from 'react';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header/Header';
import Title from '../../components/Title/Title';

import useStyles from './Style';

import Layout from '../../layouts/public/Public';

const Apresentacao = () => {
    const classes = useStyles();
    return (
        <Layout>
            <Header />

            <Container maxWidth="xl" className={classes.container} >
                <Grid container spacing={6} >
                    <Grid item spacing={3} xs={12} sm={12} lg={12} xl={12} >
                        <Title variant="h4">
                            OLIMPÍADA MIRIM – OBMEP
                        </Title>

                        <Typography className={classes.text} variant="body2">
                            A <span className={classes.bolder}>Olimpíada Mirim - OBMEP</span> é uma iniciativa do IMPA - Instituto de Matemática Pura e Aplicada e recebe apoio da B3 Social, 
                            sendo realizada também com recursos do Ministério da Educação e do Ministério da Ciência, Tecnologia e Inovação para sua realização.
                        </Typography>

                        <Typography className={classes.text} variant="body2">
                            A principal motivação para a criação da Olimpíada Mirim - OBMEP é garantir que alunos mais novos, do 2º ano ao 5º ano do ensino fundamental, 
                            de escolas públicas e privadas do país, possam vivenciar a experiência olímpica tais quais os alunos participantes da OBMEP (6º ano do ensino 
                            fundamental ao 3º ano do ensino médio), aproveitando-se do fato de que o público-alvo da Olimpíada Mirim - OBMEP ainda não nutre qualquer preconceito 
                            em relação à matemática, sendo naturalmente mais abertos à disciplina e a seus desafios.
                        </Typography>

                        <Typography className={classes.text} variant="body2">
                            Na 1ª edição da Olimpíada Mirim - OBMEP, realizada em 2022, mais de 2 milhões e setecentos mil alunos do 2º, 3º, 4º e 5º anos de mais de 18 mil de escolas públicas, 
                            tiveram a chance de testar seus conhecimentos matemáticos em duas etapas, Prova da 1ª Fase e Prova da 2ª Fase. Da 2ª edição da Olimpíada Mirim – OBMEP participaram 
                            mais de 4 milhões e duzentos mil alunos de mais de 29 mil escolas públicas e privadas.
                        </Typography>

                        <Typography className={classes.text} variant="body2">
                            A Olimpíada Mirim - OBMEP divide os alunos em dois níveis, Mirim 1 - alunos do 2º e do 3º ano do Ensino Fundamental e Mirim 2 - alunos do 4º e do 5º anos do ensino fundamental. 
                            A prova da 1ª Fase é composta de 15 questões objetivas. Os alunos classificados para a 2ª Fase realizam uma prova também composta de 15 questões objetivas.
                        </Typography>

                        <Typography className={classes.text} variant="body2">
                            Em cada uma das fases, para cada nível, o Comitê de Provas da Olimpíada Mirim - OBMEP, formado por profissionais altamente qualificados e detentores de expertise necessária 
                            para preparar questões minuciosamente desenvolvidas para o público-alvo e em linguagem condizente com a faixa etária dos alunos, elabora, em cada edição, questões diferentes 
                            que estimulam a criatividade e o raciocínio lógico dos alunos.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default Apresentacao;