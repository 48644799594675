import React from 'react'

import Grid from '@material-ui/core/Grid';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { amber, blueGrey } from '@material-ui/core/colors';

import Title from '../../components/Title/Title';
import { LinkTargetBlank } from '../../components/Link/Link';

import useStyles from './Style';

export const PrimeiraOlimpiadaMirim = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={12} >
      <Grid item xs={12} sm={12} lg={6} xl={5} >
        <div >
          <Title variant="h6">
            FASE 1
          </Title>
          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: amber[500] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1ODPUse4q4GQ3WsFeiS7P2jNqtRraWpuT/view">PROVA - MIRIM 1</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: blueGrey[600] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1PfJNAM91orv-XsSuc6GzOtQY93i2356X/view">PROVA - MIRIM 2</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: amber[500] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1Xs_Iz0hwecGcbczKKBX0x0Rrt1x4D1IR/view">SOLUÇÕES - MIRIM 1</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: blueGrey[600] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1bTuH2iudsF9mLKh8e0o-fUaMg-rFIgOa/view">SOLUÇÕES - MIRIM 2</LinkTargetBlank>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={12} lg={6} xl={5} >
        <div >
          <Title variant="h6">
            FASE 2
          </Title>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: amber[500] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1n48zPM8O-0jq_vsyi-r_uWC6H6Rv13tF/view">PROVA - MIRIM 1</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: blueGrey[600] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1-iBjSrp_U5EoepgzgjX_h7fxtnwAb0_R/view">PROVA - MIRIM 2</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: amber[500] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/105W-GcQFXJgsmnDFX7Ku5Wxw0mLQBIwd/view">SOLUÇÕES - MIRIM 1</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: blueGrey[600] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1w6wNNbHIPjkbCKSeYhiVKl4rDB4xb6Zz/view">SOLUÇÕES - MIRIM 2</LinkTargetBlank>
          </div>
        </div>
      </Grid> 
    </Grid>
  )
}

export default PrimeiraOlimpiadaMirim;
