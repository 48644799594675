import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import {
  isAfterDataProvaFase2,
  isPeriodoRegistroParticipacaoCadastroAlunos,
  isPeriodoInserirNotaFase2,
  isAfterFimPeriodoInserirNotaFase2,
  isHabilitadoUploadTermoAutorizacao,
} from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import SpellcheckOutlinedIcon from '@material-ui/icons/SpellcheckOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { green } from "@material-ui/core/colors";

import AlertaEscola, { escolaComCadastroAlunoDisponivel } from './AlertaEscola';
import Desempate from '../Premiados/Desempate';
import TermoAutorizacao from './TermoAutorizacao';
import { Nota as DialogNota } from './Nota';
import { Nome as DialogNome } from './Nome';

import { DialogConfirm as DialogFinalizarLancamentoNotas } from '../../forms/Dialog/DialogConfirm';
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { ButtonPrimary } from '../../components/Button/Button';
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Table from '../../components/Table/Table';
import {
  verifyPagingAndSorting,
  PAGE,
  SIZE,
  ASC,
  TOTAL_ELEMENTS,
  TOTAL_PAGES,
} from '../../components/Table/Utils';

import { DialogRemove as DialogRemoverAluno } from '../../forms/Dialog/DialogRemove';
import AlunoFilter from '../../forms/Aluno/AlunoFilter';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';
import { ButtonAdd } from '../../forms/Buttons/ButtonsAdd';

import ArquivoService from '../../services/Arquivo';
import AlunoService from '../../services/Aluno';
import InscricaoService from '../../services/Inscricao';
import EscolaService from '../../services/Escola';
import { errors } from '../../services/API';

import { verifyPersistence, removePagingAndSorting } from '../../helper/PaginationHelper';
import { download } from '../../helper/FileHelper';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import AuthorizedElement from '../../security/AuthorizedElement';
import {
  __CENTRAL,
  __ADMINISTRADOR,
  __COORDENADOR,
  __ALUNO_ADICIONAR,
  __ALUNO_EXPORTAR,
  __ALUNO_EDITAR,
  __ALUNO_REMOVER,
  __APOIO_SECRETARIAL,
} from '../../security/RoleConfiguration';

import {
  patternAnoLetivo,
  patternNivel,
  patternSexo,
  patternTipoProva,
} from '../../variables/Enums/Aluno';

export class Aluno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: PAGE,
      size: SIZE,
      totalElements: TOTAL_ELEMENTS,
      totalPages: TOTAL_PAGES,
      sort: [
        { orderBy: 'inscricao.escola.cdMecEscola', order: ASC },
        { orderBy: 'anoLetivo', order: ASC },
        { orderBy: 'notaFase1', order: ASC },
        { orderBy: 'notaFase2', order: ASC },
        { orderBy: 'nmAluno', order: ASC },
      ],
      defaultOrderBy: ['inscricao.escola.cdMecEscola', 'anoLetivo', 'notaFase1', 'notaFase2', 'nmAluno'],
      filtered: false,
      filters: [],
      alunos: [],
      loading: true,
      loadingButtonSave: false,
      escola: this.props.school ?? null,
      temArquivoCertificado: false,
      openModalDesempate: false,
    }

    this.state = verifyPersistence(this.state, this.props.location);
  }

  // FECHAR TOAST
  setToast = t => this.Toast = t;
  setDesempate = d => this.Desempate = d;
  setAlunoFilter = t => this.AlunoFilter = t;
  setDialogRemoverAluno = r => this.DialogRemoverAluno = r;
  setTermoAutorizacao = a => this.TermoAutorizacao = a;
  setDialogNota = n => this.DialogNota = n;
  setDialogNome = n => this.DialogNome = n;
  setDialogFinalizarLancamentoNotas = a => this.DialogFinalizarLancamentoNotas = a;

  // LABELS
  labelSemPermissao = "Você não tem permissão para fazer isso";
  labelForaPeriodoCadastroAluno = "Fora do Período de Gestão de Aluno";
  labelForaPeriodoInserirNotaFase2 = "Fora do Período de Inserção de Nota da 2ª Fase para o Aluno";
  labelProvaEspecial = "O Aluno está Marcado para Prova Especial e NÃO pode ser Removido";
  labelLancamentoFinalizado = "Lançamento de Notas da Escola Finalizado";
  labelLancamentoNaoFinalizado = "Lançamento de Notas da Escola Não Finalizado";

  labelAlterarNome = id => "Alterar Nome";
  labelEditar = id => !this.disabledEditar(id) ? isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) ? "Editar" : this.labelForaPeriodoCadastroAluno : this.isEscolaFinalizouLancamentoNotas(id) ? this.labelLancamentoFinalizado : this.labelSemPermissao;
  labelNotaFase2 = id => !this.disabledNotaFase2(id) ? isPeriodoInserirNotaFase2(this.props.contexts) || (isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) && AuthorizedFunction([__CENTRAL])) ? "Inserir Nota da 2ª Fase" : this.labelForaPeriodoInserirNotaFase2 : this.isEscolaFinalizouLancamentoNotas(id) ? this.labelLancamentoFinalizado : this.labelSemPermissao;
  labelExcluir = id => !this.disabledExcluir(id) ? isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) ? "Excluir" : this.labelForaPeriodoCadastroAluno : this.isEscolaFinalizouLancamentoNotas(id) ? this.labelLancamentoFinalizado : this.isProvaEspecial(id) && AuthorizedFunction([__CENTRAL]) ? this.labelProvaEspecial : this.labelSemPermissao;
  labelDownloadCertificado = id => !this.disabledDownloadCertificado(id) ? this.isPremiado(id) ? "Download de Certificado de Premiação" : "Download de Certificado de Participação" : !this.comPeloMenosUmaNota(id) ? "Aluno Sem Participação Resgistrada" : !isAfterFimPeriodoInserirNotaFase2(this.props.contexts) ? "Período de Lançamento de Notas Não Finalizado" : !this.isEscolaFinalizouLancamentoNotas(id) ? this.labelLancamentoNaoFinalizado : this.labelSemPermissao;

  // DISABLES
  disabledEditar = id => AuthorizedFunction([__ALUNO_EDITAR]) ? !isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) || this.isEscolaFinalizouLancamentoNotas(id) : !AuthorizedFunction([__ALUNO_EDITAR]);
  disabledNotaFase2 = id => AuthorizedFunction([__ALUNO_EDITAR]) ? !(isPeriodoInserirNotaFase2(this.props.contexts) || (isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) && AuthorizedFunction([__CENTRAL]))) || this.isEscolaFinalizouLancamentoNotas(id) : !AuthorizedFunction([__ALUNO_EDITAR]);
  disabledExcluir = id => AuthorizedFunction([__ALUNO_REMOVER]) ? !isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) || this.isEscolaFinalizouLancamentoNotas(id) || (this.isProvaEspecial(id) && AuthorizedFunction([__CENTRAL])) : !AuthorizedFunction([__ALUNO_REMOVER]);
  disabledDownloadCertificado = (id) => !this.state.temArquivoCertificado || !this.isEscolaFinalizouLancamentoNotas(id) || !this.comPeloMenosUmaNota(id) || !isAfterFimPeriodoInserirNotaFase2(this.props.contexts);;

  isProvaEspecial = id => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);
    return aluno.tipoProva !== "NORMAL"; 
  }

  isPremiado = id => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);
    return aluno.premio !== null && aluno.premio !== "Sem Premiação";
  }

  comPeloMenosUmaNota = id => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);
    return aluno.notaFase1 !== null || aluno.notaFase2 !== null;
  }

  isEscolaFinalizouLancamentoNotas = id => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);
    return aluno.inscricao.dataFinalizacaoLancamentoNotas ? true : false; 
  }

  loadData = async () => {
    await this.props.loadContext();

    let filters = verifyPagingAndSorting(this.state);

    if (this.props.school) {
      filters = filters.filter(f => f.field !== 'cdMecEscola' && f.field !== 'nmEscola')
      filters.push({ field: 'cdMecEscola', value: this.props.school.cdMecEscola });
      filters.push({ field: 'nmEscola', value: this.props.school.nmEscola });
    }

    this.setState({
      filters: [...filters],
      filtered: true,
      loading: true
    });

    await this.verificarDadosEscolaFiltrada(filters);

    await AlunoService.filters(filters)
      .then(res => {
        
        if (res.data.content) {
          this.setState({
            alunos: [...res.data.content],
            page: res.data.number,
            size: res.data.size,
            totalElements: res.data.totalElements,
            totalPages: res.data.totalPages,
            loading: false,
          });

          this.AlunoFilter.handleFilterPersistence(filters);
        } else {

          this.setState({
            alunos: [],
            page: PAGE,
            size: SIZE,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            loading: false,
          });
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })

    ArquivoService.list()
      .then(res => {
        if (res.data.content) {
          this.setState({ 
            ...this.state, 
            temArquivoCertificado: res.data.content.find(arquivo => arquivo.tipo === 'CERTIFICADO') != null, 
          });
        }
      })
  }

  buscouPorEscola = filters => {
    return filters.find(f => f.field === "cdMecEscola")
  }

  verificarDadosEscolaFiltrada = async filters => {
    const buscandoMec = this.buscouPorEscola(filters);

    if (buscandoMec) {
      if (this.state.escola && buscandoMec.value !== this.state.escola.cdMecEscola)
        this.setState({ escola: null });

      await EscolaService.find(buscandoMec.value)
        .then(res => {
          const { inscricao } = res.data
          if (inscricao) {
            this.setState({
              escola: res.data
            })
          }
        })
        .catch(error => {
          const e = errors(error);
          this.Toast.setState({
            message: {
              message: e.message,
              type: e.type,
              open: true
            }
          })
        })
    } else {
      this.setState({ escola: null });
    }
  }

  // EDIÇÃO
  handleEditClick = id => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);
    this.props.history.push({
      pathname: `/admin/${aluno.cdMecEscola}/aluno/${id}/editar`,
      state: {
        history: {
          path: this.props.school ? `/admin/inscricao/${this.state.escola.cdMecEscola}/fase-2` : `/admin/aluno`,
          state: this.state,
        }
      }
    });
  }

  // ABRIR MODAL DE CONFIRMAÇÃO DE REMOÇÃO
  handleExcluir = id => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);
    this.DialogRemoverAluno.setState({
      dialog: {
        open: true,
        title: `Deseja Remover o Aluno ${aluno.nmAluno} ?`,
        text: `O Aluno não poderá ser recuperado depois de ser removido.`,
        id: id,
        loading: false,
      }
    });
  }

  // REMOÇÃO DE ALUNO
  handleRemoverAlunoConfirm = (id) => {
    this.DialogRemoverAluno.loading();

    AlunoService.remove(id)
      .then(res => {
        this.Toast.setState({
          message: {
            message: "O Aluno foi Excluído com Sucesso.",
            type: 'success',
            open: true
          }
        })
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => {
        this.DialogRemoverAluno.close();
        this.loadData();
      });
  }

  // EXPORTAÇÂO
  handleExportPlan = () => {
    this.AlunoFilter.setState({ filter: true, exportPlan: true, }, () => this.AlunoFilter.handleFilterApply());

    this.Toast.setState({
      message: {
        message: "A Planilha está sendo gerada para Exportação.",
        type: 'info',
        open: true,
        loading: true,
        autohide: false,
      }
    })
  }

  buttonNota = (id) => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);

    return (
      <ButtonPrimary
        onClick={() => this.handleNotaFase2Click(aluno.cdAluno)}
        name={`${aluno.notaFase2 ? "Editar" : "Informar" } Nota da Fase 2`}
        disabled={this.disabledNotaFase2(id)}
        style={!aluno.notaFase2 && !this.disabledNotaFase2(id) ? { backgroundColor: green[800] } : null}
      />
    )
  }

  buttonCertificado = (id) => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);

    return (
      <ButtonPrimary
        onClick={() => this.handleDownloadCertificado(aluno.cdAluno)}
        name={`${this.isPremiado(id) ? "Certificado de Premiação" : "Certificado de Participação"}`}
        disabled={this.disabledDownloadCertificado(id)}
        style={!this.disabledDownloadCertificado(id) ? { backgroundColor: green[800] } : null}
      />
    )
  }

  textoDialogFinalizarLancamentoNotas = (escola) => {
    return (
      <> 
        {(escola.inscricao.qtdAlunosSemNotaFase2Mirim1 + escola.inscricao.qtdAlunosSemNotaFase2Mirim2 !== 0) && 
          <>Existem <b>{escola.inscricao.qtdAlunosSemNotaFase2Mirim1 + escola.inscricao.qtdAlunosSemNotaFase2Mirim2}</b> alunos sem nota na <b>Fase 2</b> e estas notas <b>NÃO</b> poderão ser adicionadas em outro momento.&nbsp;</>
        } 

        A ação não poderá ser desfeita e a inclusão de alunos e notas não poderá mais ser mais feita a partir desta finalização. 
        <br /><br />
        <b>Deseja prosseguir mesmo assim?</b>
      </>
    )
  }
  
  handleDialogFinalizarLancamentoNotas = escola => {
    this.DialogFinalizarLancamentoNotas.setState({
      dialog: {
        open: true,
        title: `Finalização de Lançamento de Notas`,
        text: this.textoDialogFinalizarLancamentoNotas(escola),
        id: escola.cdMecEscola,
        loading: false,
        showConfirm: true,
      },
    });
  }

  handleFinalizarLancamentoNotas = (mec) => {
    InscricaoService.finalizarLancamentoNotas(mec)
      .then(res => {
        if (res.data === true) {
          this.Toast.setState({
            message: {
              message: "Lançamento de Provas Finalizado e Premiação Realizada com Sucesso.",
              type: 'success',
              open: true
            }
          })
        } else if (res.data === false) {
          this.Toast.setState({
            message: {
              message: "Lançamento de Provas Finalizado. A Premiação foi iniciada, porém existem Empates que precisam ser Resolvidos.",
              type: 'warning',
              open: true
            }
          })

          this.setState({ ...this.state, openModalDesempate: true });
        } else {
          this.Toast.setState({
            message: {
              message: "Não foi Possível Realizar o Lançamento de Provas Finalizado e fazer a Premiação dos Alunos.",
              type: 'error',
              open: true
            }
          })
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => {
        this.DialogFinalizarLancamentoNotas.close();
        this.loadData();
      });
  }

  handleDownloadCertificado = (id) => {
    this.Toast.setState({
      message: {
        message: "Em Instantes o Download Iniciará Automáticamente",
        type: "info",
        open: true
      }
    });

    AlunoService.downloadCertificado(id)
      .then(res => download(res))
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      });
  }

  patternNotaFase2 = (nota, id) => isAfterDataProvaFase2(this.props.contexts) ? this.notaWithButton(nota, id) : "Prova Não Realizada";

  notaWithButton = (nota, id) => {
    const value = nota ?? "Nota Não Informada"

    const button = () => {
      return (
        <div style={{ margin: "8px" }}>
          <span style={{ display: 'block', fontWeight: 'bold', textAlign: 'center', marginBottom: "10px" }}>{value}</span>
          {this.buttonNota(id)}
        </div>
      )
    }

    return !this.disabledNotaFase2(id) ? button() : value;
  }

  handleNotaFase2Click = id => this.DialogNota.openModal(this.state.alunos.find(s => s.cdAluno === id));
  handleAlterarNomeClick = id => this.DialogNome.openModal(this.state.alunos.find(s => s.cdAluno === id));

  // FILTROS
  filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

  // PÁGINA
  handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

  // LINHAS POR PÁGINA
  handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

  // ORDENAÇÃO
  verifyOrderBy = (orderBy) => orderBy === 'cdMecEscola' ? 'inscricao.escola.cdMecEscola' : orderBy === 'nivel' ? 'anoLetivo.nivel' : orderBy;
  handleSort = (orderBy, order) => {
    if (this.state.sort.find(s => s.orderBy === this.verifyOrderBy(orderBy))) {
      let newSort = this.state.sort.filter(s => s.orderBy !== this.verifyOrderBy(orderBy));
      newSort.splice(this.state.sort.indexOf(this.state.sort.find(s => s.orderBy === this.verifyOrderBy(orderBy))), 0, { orderBy: this.verifyOrderBy(orderBy), order: order })
      this.setState({...this.state, sort: [...newSort], filtered: true });
    } else {
      this.setState({ ...this.state, sort: [...this.state.sort.filter(s => s.orderBy !== this.verifyOrderBy(orderBy)), { orderBy: this.verifyOrderBy(orderBy), order: order }], filtered: true });
    }
  }
  handleClearSort = (orderBy) => {
    this.setState({ ...this.state, filtered: true, sort: orderBy ? [...this.state.sort.filter(s => s.orderBy !== this.verifyOrderBy(orderBy))] : [...this.state.defaultOrderBy.map(d => { return { orderBy: this.verifyOrderBy(d), order: ASC }} )]});
  };

  // TOTAL DE PAGINAS
  handleTotalPages = () => this.state.totalPages;

  // TOTAL DE ELEMENTOS
  handleTotalElements = () => this.state.totalElements;

  componentDidMount() { this.loadData(); }

  render() {
    const pageName = 'Listagem de Alunos';
    const links = [];

    const columns = [
      { label: '#', name: 'cdAluno', func: null, key: true },
      { label: 'INEP da Escola', name: 'cdMecEscola', func: null, key: false, ordering: false },
      { label: 'Nome', name: 'nmAluno', func: null, key: false },
      { label: 'Sexo', name: 'sexo', func: patternSexo, key: false },
      { label: 'Data de Nascimento', name: 'dataNascimento', func: null, key: false },
      { label: 'Nível', name: 'nivel', func: patternNivel, key: false, ordering: false },
      { label: 'Ano Letivo', name: 'anoLetivo', func: patternAnoLetivo, key: false},
      { label: 'Nota - Fase 1', name: 'notaFase1', func: null, key: false },
      { label: 'Tipo de Prova', name: 'tipoProva', func: patternTipoProva, key: false },
      { label: 'Nota - Fase 2', name: 'notaFase2', func: this.patternNotaFase2, key: false },
      { label: 'Prêmio', name: 'premio', func: null, key: false, ordering: false },
    ];

    const actions = [
      {
        name: this.labelEditar,
        func: this.handleEditClick,
        icon: <EditOutlinedIcon />,
        disabled: this.disabledEditar,
      },
      {
        name: this.labelDownloadCertificado,
        func: () => null,
        icon: this.buttonCertificado,
        disabled: this.disabledDownloadCertificado,
      },
      {
        name: this.labelNotaFase2,
        func: this.handleNotaFase2Click,
        icon: this.buttonNota,
        disabled: this.disabledNotaFase2,
      },
    ]

    if (isHabilitadoUploadTermoAutorizacao(this.props.contexts) && this.TermoAutorizacao && this.state.alunos.length > 0) {
      actions.push({
        name: this.TermoAutorizacao.labelTermo,
        func: this.TermoAutorizacao.handleTermoDownloadClick,
        icon: this.TermoAutorizacao.buttonTermo,
        disabled: this.TermoAutorizacao.disabledTermo,
      });
    }
    
    actions.push({
      name: this.labelExcluir,
      func: this.handleExcluir,
      icon: <DeleteOutlinedIcon />,
      disabled: this.disabledExcluir,
      color: "secondary"
    });

    if (AuthorizedFunction([__CENTRAL]) || AuthorizedFunction([__ADMINISTRADOR])) {
      actions.unshift({
        name: this.labelAlterarNome,
        func: this.handleAlterarNomeClick,
        icon: <SpellcheckOutlinedIcon />,
        disabled: () => false,
      });
    }

    return (
      <>
        <Toast parentRef={this.setToast} />
        {!this.props.school &&
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs links={links} active={pageName} />
            </Grid>
          </Grid>
        }
        <Title>{pageName}</Title>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AlunoFilter
              parentRef={this.setAlunoFilter}
              handleFilterChange={this.filterData}
              disabledFilterSchool={this.props.school ? true : false}
            />
          </Grid>
          <Grid item xs={12}>
            {this.state.escola &&
              <>
                <AlertaEscola
                  escola={this.state.escola} 
                  contexts={this.props.contexts}
                  finalizarLancamentoNotas={this.handleDialogFinalizarLancamentoNotas}
                />
                
                {this.state.escola.inscricao && this.state.escola.inscricao.statusPremiacao &&
                  <Desempate 
                    parentRef={this.setDesempate} 
                    handleClose={this.loadData} 
                    mec={this.state.escola.cdMecEscola} 
                    statusPremiacao={this.state.escola.inscricao.statusPremiacao} 
                    mostrarBotaoListagemPremiados={true}
                    openModal={this.state.openModalDesempate}
                  />
                }
              </>
            }
            <Grid container spacing={3}>
              <AuthorizedElement roles={[__ALUNO_ADICIONAR]}>
                {isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) && escolaComCadastroAlunoDisponivel(this.state.escola) && !this.state.escola.inscricao.dataFinalizacaoLancamentoNotas &&
                  <ButtonAdd
                    to={{
                      pathname: `/admin/${this.state.escola.cdMecEscola}/aluno/adicionar`,
                      state: {
                        history: {
                          path: this.props.school ? `/admin/inscricao/${this.state.escola.cdMecEscola}/fase-2` : '/admin/aluno',
                          state: this.state,
                        }
                      }
                    }}
                    title="Adicionar Aluno"
                  />
                }
              </AuthorizedElement>
              <AuthorizedElement roles={[__ALUNO_EXPORTAR]}>
                {this.state.filters.length > 3 &&
                  <ButtonExport
                    title="Exportar Planilha"
                    onClick={this.handleExportPlan}
                  />
                }
              </AuthorizedElement>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Table
              columns={columns}
              tableData={this.state.alunos}
              actions={AuthorizedFunction([__COORDENADOR]) || AuthorizedFunction([__APOIO_SECRETARIAL]) ? [] : actions}
              page={this.state.page}
              handlePage={this.handlePage}
              handleTotalPages={this.handleTotalPages}
              handleTotalElements={this.handleTotalElements}
              rowsPerPage={this.state.size}
              handleRowsPerPage={this.handleRowsPerPage}
              sort={this.state.sort}
              handleSort={this.handleSort}
              handleClearSort={this.handleClearSort}
              loading={this.state.loading}
            />
          </Grid>
        </Grid>

        {(isPeriodoInserirNotaFase2(this.props.contexts) || (isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) && AuthorizedFunction([__CENTRAL]))) && this.state.escola && !this.state.escola.inscricao.dataFinalizacaoLancamentoNotas &&  
          <>
            <ButtonPrimary
              startIcon={<CheckOutlinedIcon />}
              onClick={() => this.handleDialogFinalizarLancamentoNotas(this.state.escola)}
              name="Após Informar as Notas de 2ª Fase, CLIQUE AQUI para Finalizar Lançamento de Notas e Realizar Premiação"
              style={{ backgroundColor: green[800] }}
            />

            <DialogFinalizarLancamentoNotas
              parentRef={this.setDialogFinalizarLancamentoNotas}
              handleConfirm={this.handleFinalizarLancamentoNotas}
            />
          </>
        }

        {this.state.alunos.length > 0 &&
          <TermoAutorizacao 
            parentRef={this.setTermoAutorizacao}
            handleClose={this.loadData}
            alunos={[ ...this.state.alunos ]}
          />
        }

        {AuthorizedFunction([__ALUNO_REMOVER]) &&
          <DialogRemoverAluno
            parentRef={this.setDialogRemoverAluno}
            handleConfirm={this.handleRemoverAlunoConfirm}
          />
        }

        {(isPeriodoInserirNotaFase2(this.props.contexts) || (isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) && AuthorizedFunction([__CENTRAL]))) &&
          <DialogNota
            parentRef={this.setDialogNota}
            handleClose={this.loadData}
          />
        }

        {(AuthorizedFunction([__CENTRAL]) || AuthorizedFunction([__ADMINISTRADOR])) && 
          <DialogNome
            parentRef={this.setDialogNome}
            handleClose={this.loadData}
          />
        }
      </>
    )
  }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Aluno));