import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import useStyles from './Style';

import { getFullName } from '../../security/keycloak';

import logo from '../../assets/img/logo-horizontal-fundo-azul.png';

export function Navbar (props) {
    const classes = useStyles();
    let history = useHistory();

    const handleCloseAppClick = () => {
        props.handleLogout({ redirectUri: process.env.REACT_APP_PUBLIC_URL });
        history.push("/");
    }

    const handleHomeClick = () => {
        history.push("/");
    }

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, 'no-print', {
                [classes.appBarShift]: props.open,
            })}
        >
            <Toolbar>
                <Grid 
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid 
                        item
                        container 
                        direction="row" 
                        xs={6} 
                        lg={6} 
                        justifyContent="flex-start"
                    >
                        { !props.open &&
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={props.click}
                                edge="start"
                                className={clsx(classes.menuButton, props.open && classes.hide)}
                            >
                                <MenuIcon />
                            </IconButton>
                        }
                        <Link to={"/"} >
                            <img src={logo} className={classes.logoNav} alt={props.nameApp}/>
                        </Link>
                    </Grid>
                    <Grid 
                        item
                        container 
                        direction="row" 
                        xs={5} 
                        lg={5} 
                        justifyContent="flex-end"
                    >
                        <Typography variant="h6" noWrap>
                            {getFullName()}
                        </Typography>
                    </Grid>
                    <Grid 
                        item 
                        container
                        direction="row" 
                        xs={1} 
                        lg={1} 
                        justifyContent="flex-end"  
                    >
                        <Tooltip title="Página Inicial">
                            <IconButton
                                color="inherit"
                                aria-label="Página Inicial"
                                edge="start"
                                onClick={handleHomeClick}
                            >
                                <HomeIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Sair">
                            <IconButton
                                color="inherit"
                                aria-label="Sair"
                                edge="end"
                                onClick={handleCloseAppClick}
                            >
                                <ExitToAppIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;