import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Tab } from '@material-ui/core'
import { TabPanel, TabContext, TabList } from '@material-ui/lab'

import Header from '../../components/Header/Header';
import Title from '../../components/Title/Title';

import SegundaMirim from './SegundaMirim';
import PrimeiraMirim from './PrimeiraMirim';
import NivelA from './NivelA';

import Layout from '../../layouts/public/Public';

import useStyles from './Style';

const ProvasSolucoes = () => {
    const classes = useStyles();

    const [tab, setTab] = useState("2");
  
    const handleChange = (e, newTab) => {
      setTab(newTab);
    };
  
    return (
        <Layout>
            <Header />
            
            <Container maxWidth="xl" className={classes.container} >
                <Grid container spacing={12} >
                    <Title variant="h4">
                        PROVAS E SOLUÇÕES (Edições Anteriores)
                    </Title>
                </Grid>

                <Grid item xs={12} >
                    <Paper style={{ padding: '24px' }} elevation={2}>
                        <TabContext value={tab}>
                            <TabList onChange={handleChange} indicatorColor="primary" textColor="primary" variant="fullWidth">
                                <Tab label="2ª Olimpíada Mirim" value="2" />
                                <Tab label="1ª Olimpíada Mirim" value="1" />
                                <Tab label="OBMEP - Nivel A (4º e 5º anos)" value="0" />
                            </TabList>

                            <TabPanel value="2">
                                <SegundaMirim />
                            </TabPanel>
                            <TabPanel value="1">
                                <PrimeiraMirim />
                            </TabPanel>
                            <TabPanel value="0">
                                <NivelA />
                            </TabPanel>
                        </TabContext>
                    </Paper>
                </Grid>
            </Container>
        </Layout>
    )
}

export default ProvasSolucoes;