import { toDateTimeForCompare, toDateTime,toDateTimeWithoutSeconds } from './DateHelper';

import AuthorizedFunction from '../security/AuthorizedFunction';
import { __CENTRAL, __COORDENADOR, __APOIO_SECRETARIAL } from '../security/RoleConfiguration';

// CONSTANTES DE VARIAVEIS DE CONTEXTO NO BANCO DE DADOS
const EDICAO = 1;
const INICIO_INSCRICOES_PUBLICO = 2;
const FIM_INSCRICOES_PUBLICO = 3;
const INICIO_ENVIO_OBJETOS_POSTAIS_2_FASE = 4;
const FIM_ENVIO_OBJETOS_POSTAIS_2_FASE = 5;
const INICIO_RASTREAMENTO_OBJETOS_POSTAIS_2_FASE = 6;
const FIM_RASTREAMENTO_OBJETOS_POSTAIS_2_FASE = 7;
const INICIO_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS = 8;
const FIM_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS = 9;
const DATA_PROVA_FASE_1 = 10;
const PERMITE_CADASTRO_ALUNOS_PROVAS_ESPECIAIS = 11;
const DATA_PROVA_FASE_2 = 25;
const INICIO_PERIODO_INSERIR_NOTA_2_FASE = 26;
const FIM_PERIODO_INSERIR_NOTA_2_FASE = 27;
const INICIO_PERIODO_PAGAMENTO = 28;
const FIM_PERIODO_PAGAMENTO = 29;
const INICIO_INSCRICOES_CENTRAL = 31;
const FIM_INSCRICOES_CENTRAL = 32;
const INICIO_INSCRICOES_COORDENADORES = 33;
const FIM_INSCRICOES_COORDENADORES = 34;
const INICIO_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS_CENTRAL = 35;
const FIM_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS_CENTRAL = 36;
const FIM_PERIODO_RESOLVER_PROBLEMAS_EMPATE_PREMIACAO = 37;
const PERMITE_UPLOAD_TERMO_AUTORIZACAO = 39;

// MÉTODO SIMPLES DE BUSCA DE VARIAVEL DE CONTEXTO
export const takeContext = (contexts, id) => {
    const c = contexts.find(c => c.cdVariavel === id)
    return c ? id === EDICAO || id === PERMITE_CADASTRO_ALUNOS_PROVAS_ESPECIAIS || id === PERMITE_UPLOAD_TERMO_AUTORIZACAO ? c.nmValor : toDateTimeForCompare(c.nmValor) : null;
}

// OPERAÇÕES LÓGICAS BÁSICAS DE CONTEXTUALIZAÇÃO
export const isBefore = (contexts, id) => takeContext(contexts, id) ? takeContext(contexts, id) > new Date() : false;
export const isAfter = (contexts, id) => takeContext(contexts, id) ? takeContext(contexts, id) < new Date() : false;
export const isBeforeOrEqual = (contexts, id) => takeContext(contexts, id) ? takeContext(contexts, id) >= new Date() : false;
export const isAfterOrEqual = (contexts, id) => takeContext(contexts, id) ? takeContext(contexts, id) <= new Date() : false;
export const isBetween = (contexts, idDate1, idDate2) => isAfterOrEqual(contexts, idDate1) && isBeforeOrEqual(contexts, idDate2);
export const isNotBetween = (contexts, idDate1, idDate2) => isBefore(contexts, idDate1) && isAfter(contexts, idDate2);
export const isTrue = (contexts, id) => takeContext(contexts, id) === "true";


/////////////// OS MÉTODOS ABAIXO ESPERAM A PASSAGEM DO STORE COM CONTEXTO CARREGADO NAS VIEWS QUE SERÃO CONTEXTUALIZADAS ///////////////

// MÉTODO DE BUSCA DE NÚMERO DA EDIÇÃO
export const getEdicao = contexts => takeContext(contexts, EDICAO);

export const getPeriodoDePagamentoFormatado = contexts => toDateTimeWithoutSeconds(takeContext(contexts, INICIO_PERIODO_PAGAMENTO)) + " até " +  toDateTimeWithoutSeconds(takeContext(contexts, FIM_PERIODO_PAGAMENTO));

export const getDataFimDePagamentoFormatado = contexts => toDateTimeWithoutSeconds(takeContext(contexts, FIM_PERIODO_PAGAMENTO));

export const getDataFimPeriodoDeInscricaoFormatado = contexts => toDateTimeWithoutSeconds(takeContext(contexts, FIM_INSCRICOES_PUBLICO));

export const getDataFimResolverProblemasEmpatesPremiacao = contexts => toDateTimeWithoutSeconds(takeContext(contexts, FIM_PERIODO_RESOLVER_PROBLEMAS_EMPATE_PREMIACAO));

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE INSCRIÇÕES
export const isPeriodoInscricoes = contexts => 
    (isBetween(contexts, INICIO_INSCRICOES_PUBLICO, FIM_INSCRICOES_PUBLICO) && !AuthorizedFunction([__CENTRAL, __COORDENADOR, __APOIO_SECRETARIAL]))
        || (isBetween(contexts, INICIO_INSCRICOES_CENTRAL, FIM_INSCRICOES_CENTRAL) && AuthorizedFunction([__CENTRAL]))
            || (isBetween(contexts, INICIO_INSCRICOES_COORDENADORES, FIM_INSCRICOES_COORDENADORES) && AuthorizedFunction([__COORDENADOR, __APOIO_SECRETARIAL]));

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE INSCRIÇÕES DAS ESCOLAS
export const isPeriodoInscricoesParaEscolas = contexts => isBetween(contexts, INICIO_INSCRICOES_PUBLICO, FIM_INSCRICOES_PUBLICO);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE PAGAMENTOS
export const isPeriodoPagamento = contexts => isBetween(contexts, INICIO_PERIODO_PAGAMENTO, FIM_PERIODO_PAGAMENTO);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE REGISTRO DE PARTICIPAÇÕES
export const isPeriodoRegistroParticipacaoCadastroAlunos = contexts => 
    (isBetween(contexts, INICIO_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS, FIM_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS) && !AuthorizedFunction([__CENTRAL]))
        || (isBetween(contexts, INICIO_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS_CENTRAL, FIM_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS_CENTRAL) && AuthorizedFunction([__CENTRAL]));

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DEPOIS DO PERÍODO DE REGISTRO DE PARTICIPAÇÕES
export const isAfterPeriodoRegistroParticipacaoCadastroAlunos = contexts => 
    (isAfter(contexts, FIM_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS) && !AuthorizedFunction([__CENTRAL]))
        || (isAfter(contexts, FIM_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS_CENTRAL) && AuthorizedFunction([__CENTRAL]));

// MÉTODO BOLEANO PARA VERIFICAR SE JÁ INICIOU O PERÍODO DE REGISTRO DE PARTICIPAÇÕES
export const isAfterInicioPeriodoRegistroParticipacaoCadastroAlunos = contexts =>
    (isAfter(contexts, INICIO_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS) && !AuthorizedFunction([__CENTRAL]))
        || (isAfter(contexts, INICIO_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS_CENTRAL) && AuthorizedFunction([__CENTRAL]));

export const isBeforeDataInicioPagamento = contexts => isBefore(contexts, INICIO_PERIODO_PAGAMENTO);

export const isAfterDataFimInscricaoEDataFimPagamento = contexts => 
    ((isAfter(contexts, FIM_INSCRICOES_PUBLICO)  && !AuthorizedFunction([__CENTRAL, __COORDENADOR, __APOIO_SECRETARIAL]))
        || (isAfter(contexts, FIM_INSCRICOES_CENTRAL) && AuthorizedFunction([__CENTRAL]))
            || (isAfter(contexts, FIM_INSCRICOES_COORDENADORES)  && AuthorizedFunction([__COORDENADOR, __APOIO_SECRETARIAL])))
    && isAfter(contexts, FIM_PERIODO_PAGAMENTO);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DEPOIS DA DATA DA PROVA
export const isAfterDataProvaFase1 = contexts => isAfter(contexts, DATA_PROVA_FASE_1);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DEPOIS DA DATA DA PROVA
export const isAfterDataProvaFase2 = contexts => isAfter(contexts, DATA_PROVA_FASE_2);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE ENVIO DE OBJETOS POSTAIS
export const isPeriodoEnvioObjetosPostais2Fase = contexts => isBetween(contexts, INICIO_ENVIO_OBJETOS_POSTAIS_2_FASE, FIM_ENVIO_OBJETOS_POSTAIS_2_FASE);

// MÉTODO PARA BUSCA DE INICIO DE ENVIO DE OBJETOS POSTAIS
export const getInicioEnvioObjetosPostais2Fase = contexts => toDateTime(takeContext(contexts, INICIO_ENVIO_OBJETOS_POSTAIS_2_FASE));

// MÉTODO PARA BUSCA DE TERMINO DE ENVIO DE OBJETOS POSTAIS
export const getTerminoEnvioObjetosPostais2Fase = contexts => toDateTime(takeContext(contexts, FIM_ENVIO_OBJETOS_POSTAIS_2_FASE));

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE RASTREAMENTO DE OBJETOS POSTAIS
export const isPeriodoRastreamentoObjetosPostais2Fase = contexts => isBetween(contexts, INICIO_RASTREAMENTO_OBJETOS_POSTAIS_2_FASE, FIM_RASTREAMENTO_OBJETOS_POSTAIS_2_FASE);

// MÉTODO PARA BUSCA DE INICIO DE RASTREAMENTO DE OBJETOS POSTAIS
export const getInicioRastreamentoObjetosPostais2Fase = contexts => toDateTime(takeContext(contexts, INICIO_RASTREAMENTO_OBJETOS_POSTAIS_2_FASE));

// MÉTODO PARA BUSCA DE TERMINO DE RASTREAMENTO DE OBJETOS POSTAIS
export const getTerminoRastreamentoObjetosPostais2Fase = contexts => toDateTime(takeContext(contexts, FIM_RASTREAMENTO_OBJETOS_POSTAIS_2_FASE));

// MÉTODO BOLEANO PARA VERIFICAR SE PERMITE CADASTRO ALUNOS PARA PROVAS ESPECIAIS
export const isPermiteCadastroAlunosProvasEspeciais = contexts => isTrue(contexts, PERMITE_CADASTRO_ALUNOS_PROVAS_ESPECIAIS);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE INSERÇÃO DE NOTA DA 2ª FASE 
export const isPeriodoInserirNotaFase2 = contexts => isBetween(contexts, INICIO_PERIODO_INSERIR_NOTA_2_FASE, FIM_PERIODO_INSERIR_NOTA_2_FASE);

// MÉTODO BOLEANO PARA VERIFICAR SE JÁ INICIOU O PERÍODO DE INSERÇÃO DE NOTA DA 2ª FASE 
export const isAfterInicioPeriodoInserirNotaFase2 = contexts => isAfter(contexts, INICIO_PERIODO_INSERIR_NOTA_2_FASE);

// MÉTODO BOLEANO PARA VERIFICAR SE JÁ TERMINOU O PERÍODO DE INSERÇÃO DE NOTA DA 2ª FASE 
export const isAfterFimPeriodoInserirNotaFase2 = contexts => isAfter(contexts, FIM_PERIODO_INSERIR_NOTA_2_FASE);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE RESOLUÇÃO DE PROBLEMAS DE EMPATE EM PREMIAÇÃO
export const isPeriodoResolverProblemasEmpatesPremiacao = contexts => isBetween(contexts, INICIO_PERIODO_INSERIR_NOTA_2_FASE, FIM_PERIODO_RESOLVER_PROBLEMAS_EMPATE_PREMIACAO);

export const isHabilitadoUploadTermoAutorizacao = contexts => isTrue(contexts, PERMITE_UPLOAD_TERMO_AUTORIZACAO);