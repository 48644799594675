import React from 'react';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header/Header';
import Title from '../../components/Title/Title';

import useStyles from './Style';

import Layout from '../../layouts/public/Public';

const Contato = () => {
    const classes = useStyles();
    return (
        <Layout>
            <Header />

            <Container maxWidth="xl" className={classes.container} >
                <Grid container spacing={6} >
                    <Grid item spacing={3} xs={12} sm={12} lg={12} xl={12} >
                        <Title variant="h4">
                            CONTATO
                        </Title>

                        <Title variant="h6" className={classes.subtitle}>
                            ENTRE EM CONTATO COM A OLIMPÍADA MIRIM - OBMEP:
                        </Title>

                        <Typography className={classes.text} variant="body2">
                            <span className={classes.bolder}>Telefone</span>: (21) 2529-5084
                        </Typography>

                        <Typography className={classes.text} variant="body2">
                            <span className={classes.bolder}>E-mail</span>: olimpiadamirim@obmep.org.br
                        </Typography>

                        <Typography className={classes.text} variant="body2">
                            <span className={classes.bolder}>Horário de atendimento</span>: Segunda a sexta, de 08h às 18h (horário de Brasília)
                        </Typography>

                        <Typography className={classes.text} variant="body2">
                            <span className={classes.bolder}>Endereço</span>: Estrada Dona Castorina, 110 - Rio de Janeiro - RJ
                        </Typography>
                        
                        <Typography className={classes.text} variant="body2">
                            <span className={classes.bolder}>CEP</span>: 22460-320
                        </Typography> 
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default Contato;