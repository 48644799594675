import React from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header/Header';
import Title from '../../components/Title/Title';

import useStyles from './Style';

import Atualizacao from './Atualizacao';
import Compartilhamento from './Compartilhamento';
import Cookies from './Cookies';
import DadosUtilizados from './DadosUtilizados';
import Direitos from './Direitos';
import MedidasSeguranca from './MedidasSeguranca';
import Objetivo from './Objetivo';
import QuemSomos from './QuemSomos';

import Layout from '../../layouts/public/Public';

const Privacidade = () => {
    const classes = useStyles();
    return (
        <Layout>
            <Header />

            <Container maxWidth="xl" className={classes.container} >
                <Grid container spacing={6} >
                    <Grid item spacing={3} xs={12} sm={12} lg={12} xl={12} >
                        <Title variant="h4">
                            AVISO EXTERNO DE PRIVACIDADE
                        </Title>

                        <QuemSomos />
                        <Objetivo />
                        <DadosUtilizados />                        
                        <Cookies />
                        <Compartilhamento />
                        <MedidasSeguranca />
                        <Direitos />
                        <Atualizacao />
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default Privacidade;