import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import SelectOption from '../../components/Inputs/Select/Select';
import Text from '../../components/Inputs/Text/Text';
import { 
    optionsOrigem, 
    optionsStatus, 
    patternTipoOcorrencia 
} from '../../variables/Enums/Escola';

import AuthorizedFunction from '../../security/AuthorizedFunction';

import {
    isBlank,
    isBlankHelperText,
} from '../../helper/ValidationHelper';

import { __ESCOLA_ADICIONAR_OPCOES_AVANCADAS } from '../../security/RoleConfiguration';
import AuthorizedElement from '../../security/AuthorizedElement';

const limiteCaracteresJustificativa = 400;

export class EscolaStatus extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            origem: "",
            status: "",
            tipoOcorrencia : "",
            justificativa: "",
            possuiConvenio: props.possuiConvenio,
            errors: {
                origem: false,
                status: false,
                tipoOcorrencia: false,
                justificativa: false,
            },
            helpers: {
                origem: null,
                status: null,
                tipoOcorrencia: null,
                justificativa: null,
            }
        }
        this.isValid = this.isValid.bind(this);
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null }
        })
    }

    // VALIDAÇÕES
    isValid = () => {
        if ((AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && !this.props.escola && (isBlank(this.state.status) || isBlank(this.state.origem))) || 
            (AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && isBlank(this.state.justificativa) && this.state.status === "INVALIDA")) {
            this.setState({
                errors: {
                    origem: AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && !this.props.escola && isBlank(this.state.origem) ? true : false,
                    status: AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && !this.props.escola && isBlank(this.state.status) ? true : false,
                    justificativa: AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && isBlank(this.state.justificativa) && this.state.status === "INVALIDA" ? true : false,
                },
                helpers: {
                    origem: AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && !this.props.escola && isBlank(this.state.origem) ? isBlankHelperText() : null,
                    status: AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && !this.props.escola && isBlank(this.state.status) ? isBlankHelperText() : null,
                    justificativa: AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && isBlank(this.state.justificativa) && this.state.status === "INVALIDA" ? isBlankHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }   

    handleChangeCheckbox = e => {
        this.setState({
          [e.target.name]: e.target.checked,
        });
      }

    render () {
        return (
            <>
                 <Grid container spacing={3} style={{ backgroundColor:"#f5f5f5", marginTop:10 }}>
                    <AuthorizedElement roles={[__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]} >
                        <Grid item container spacing={3}>
                            <Grid item sm={12} lg={2}>
                                <SelectOption
                                    required
                                    label="Status"
                                    name='status'
                                    value={this.state.status}
                                    onChange={(e) => this.handleChange(e)}
                                    options={optionsStatus}
                                    hiddenblank="true"
                                    error={this.state.errors.status}
                                    helperText={this.state.helpers.status}
                                />
                            </Grid>
                            { this.state.tipoOcorrencia && this.state.tipoOcorrencia !== "" &&
                                <Grid item sm={12} lg={3}>
                                    <Text
                                        disabled={true}
                                        name="motivoAnalise"
                                        label="Motivo da Análise"
                                        value={patternTipoOcorrencia(this.state.tipoOcorrencia)}
                                        error={this.state.errors.tipoOcorrencia}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.tipoOcorrencia}
                                    />
                                </Grid>
                            }
                            <Grid item sm={12} lg={3}>
                                <SelectOption
                                    required
                                    label="Origem"
                                    name='origem'
                                    value={this.state.origem}
                                    onChange={(e) => this.handleChange(e)}
                                    options={optionsOrigem}
                                    hiddenblank="true"
                                    error={this.state.errors.origem}
                                    helperText={this.state.helpers.origem}
                                />
                            </Grid>
                            {(this.state.status === "INVALIDA") &&
                                <Grid item sm={12} lg={9}>
                                    <Text
                                        required
                                        multiline={true}
                                        rows={4}
                                        name="justificativa"
                                        label="Justificativa de Status"
                                        value={this.state.justificativa}
                                        error={this.state.errors.justificativa}
                                        onChange={this.handleChange}
                                        helperText={ `${this.state.helpers.justificativa ?? ""} Caracteres: ${this.state.justificativa ? this.state.justificativa.length : 0}/${limiteCaracteresJustificativa}`}
                                        inputProps={{maxLength: limiteCaracteresJustificativa}}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </AuthorizedElement>
                </Grid>
            </>
        )
    }
}

export default EscolaStatus;