import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import SelectOption from '../../components/Inputs/Select/Select';
import Toast from '../../components/Toast/Toast';

import UFService from '../../services/UF';
import { patternUF } from '../../variables/Enums/UF';

import CidadeService from '../../services/Cidade';
import { patternCity, patternRegion } from '../../variables/Enums/Cidade';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import { __COORDENADOR, __APOIO_SECRETARIAL } from '../../security/RoleConfiguration';
import { 
    getCoordenadorUF, 
    getCoordenadorRegiao,
    getApoioSecretarialUF, 
    getApoioSecretarialRegiao, 
} from '../../security/keycloak';

export class EnderecoFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                UF: AuthorizedFunction([__COORDENADOR]) ? getCoordenadorUF() : AuthorizedFunction([__APOIO_SECRETARIAL]) ? getApoioSecretarialUF() : '',
                region: AuthorizedFunction([__COORDENADOR]) ? getCoordenadorRegiao() : AuthorizedFunction([__APOIO_SECRETARIAL]) ? getApoioSecretarialRegiao() : '',
                city: '',
            },
            disabled: {
                uf: AuthorizedFunction([__COORDENADOR]) || AuthorizedFunction([__APOIO_SECRETARIAL]) ? true : false,
                region: AuthorizedFunction([__COORDENADOR]) || AuthorizedFunction([__APOIO_SECRETARIAL]) ? true : false,
                city: AuthorizedFunction([__COORDENADOR]) || AuthorizedFunction([__APOIO_SECRETARIAL]) ? false : true,
            },
            optionsUF: [],
            optionsCity: [],
            optionsRegion: [],
        }
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            filters: {...this.state.filters, [e.target.name]: e.target.value },
        }, e.target.name === 'UF' || e.target.name === 'region' ? this.findCities : null)
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // BUSCAR MUNICIPIOS POR UF E REGIAO SELECIONADOS
    findCities = () => {  
        if (!this.props.disabledCities) {      
            if (!this.state.filters.UF && !this.state.filters.region) {
                this.limparBuscas();
                return
            }

            this.carregarRegioes();                
            this.carregarCidades();
        }
    }

    carregarUFs = () => {
        UFService.list()
            .then(res => {
                this.setState({
                    filters: {
                        ...this.state.filters,
                        UF: AuthorizedFunction([__COORDENADOR]) ? getCoordenadorUF() : AuthorizedFunction([__APOIO_SECRETARIAL]) ? getApoioSecretarialUF() : '',
                    },
                    optionsUF: [...patternUF(res.data)]
                })
            })
            .catch(err => {
                this.Toast.setState({
                    message: {
                        message: "Não Foi Possível Buscar as UF's.",
                        type: 'error',
                        open: true
                    }
                })
            })
    }

    carregarRegioes = () => {   
        if (this.props.withRegion) {      
            const f = [];

            if (this.state.filters.UF) f.push({ field: "uf", value: this.state.filters.UF });

            CidadeService.filterRegions(f)
                .then(res => {
                    this.setState({
                        filters: {
                            ...this.state.filters,
                            region: this.state.filters.region.includes(this.state.filters.UF) ? this.state.filters.region : '',
                        },
                        optionsRegion: [...patternRegion(res.data)]
                    })
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar as Regiões.",
                            type: 'error',
                            open: true
                        }
                    })
                })
        }
    }

    carregarCidades = () => {
        const f = [];

        if (this.state.filters.UF) f.push({ field: "uf", value: this.state.filters.UF });
        if (this.state.filters.region) f.push({ field: "regiao", value: this.state.filters.region });

        if (f.length > 0) {
            CidadeService.filters(f)
                .then(res => {
                    this.setState({
                        filters: {
                            ...this.state.filters,
                            city: '',
                        },
                        disabled:{
                            ...this.state.disabled,
                            city: false,
                        },
                        optionsCity: [...patternCity(res.data)]
                    })
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar as Cidades.",
                            type: 'error',
                            open: true
                        }
                    })
                })
        }
    }

    limparBuscas = () => {
        this.setState({ 
            filters: {
                UF: AuthorizedFunction([__COORDENADOR]) ? getCoordenadorUF() : AuthorizedFunction([__APOIO_SECRETARIAL]) ? getApoioSecretarialUF() : '',
                region: AuthorizedFunction([__COORDENADOR]) ? getCoordenadorRegiao() : AuthorizedFunction([__APOIO_SECRETARIAL]) ? getApoioSecretarialRegiao() : '',
                city: '',
            },
            disabled:{
                uf: AuthorizedFunction([__COORDENADOR]) || AuthorizedFunction([__APOIO_SECRETARIAL]) ? true : false,
                region: AuthorizedFunction([__COORDENADOR]) || AuthorizedFunction([__APOIO_SECRETARIAL]) ? true : false,
                city: AuthorizedFunction([__COORDENADOR]) || AuthorizedFunction([__APOIO_SECRETARIAL]) ? false : true,
            },
            optionsCity: AuthorizedFunction([__COORDENADOR]) || AuthorizedFunction([__APOIO_SECRETARIAL]) ? this.state.optionsCity : [],
        }, () => this.carregarRegioes());
    }

    componentDidMount () {
        this.carregarUFs();
        this.carregarRegioes();
        this.carregarCidades();
    }

    render () {
        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid item sm={12} lg={2}>
                    <SelectOption
                        label="UF"
                        name='UF'
                        value={AuthorizedFunction([__COORDENADOR]) ? getCoordenadorUF() : AuthorizedFunction([__APOIO_SECRETARIAL]) ? getApoioSecretarialUF() : this.state.filters.UF}
                        onChange={this.handleChange}
                        options={this.state.optionsUF}
                        disabled={this.state.disabled.uf}
                    />
                </Grid>                
                { this.props.withRegion &&
                    <Grid item sm={12} lg={1}>
                        <SelectOption
                            label="Região"
                            name='region'
                            value={AuthorizedFunction([__COORDENADOR]) ? getCoordenadorRegiao() : AuthorizedFunction([__APOIO_SECRETARIAL]) ? getApoioSecretarialRegiao() : this.state.filters.region}
                            onChange={this.handleChange}
                            options={this.state.optionsRegion}
                            disabled={this.state.disabled.region}
                        />
                    </Grid>
                }
                { !this.props.disabledCities &&
                    <Grid item sm={12} lg={3}>
                        <SelectOption
                            label="Município"
                            name='city'
                            value={this.state.filters.city}
                            onChange={this.handleChange}
                            options={this.state.optionsCity}
                            disabled={this.state.disabled.city}
                        />
                    </Grid>
                }
            </>
        )
    }
}

export default EnderecoFilter;