import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import { Tab } from '@material-ui/core';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import {
    getInicioEnvioObjetosPostais2Fase,
    getTerminoEnvioObjetosPostais2Fase,
    getInicioRastreamentoObjetosPostais2Fase,
    getTerminoRastreamentoObjetosPostais2Fase,
} from '../../helper/ContextHelper';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';

import AtualizacaoStatusSedex from './AtualizacaoStatusSedex';
import ImportacaoObjetoPostal from './ImportacaoObjetoPostal';
import ImportacaoPlanilhaObjetoPostal from './ImportacaoPlanilhaObjetoPostal';

import { Layout } from '../../layouts/private/Private';

export class Tarefa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: "0",
        }
    }

    handleTab = (e, newTab) => {
        this.setState({ tab: newTab });
    }

    componentDidMount() {
        this.props.loadContext();
    }

    render() {
        const pageName = 'Tarefas';
        const links = [];

        const contexts = this.props.contexts;

        return (
            <Layout>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={pageName} />
                    </Grid>
                </Grid>
                <Title>{pageName}</Title>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper style={{ padding: '24px' }} elevation={1}>
                            <TabContext value={this.state.tab}>
                                <TabList onChange={this.handleTab} indicatorColor="primary" textColor="primary" variant="fullWidth">
                                    <Tab label="Importação de Objetos Postais da Gráfica" value="0" />
                                    <Tab label="Importação de Objetos Postais de Medalhas" value="1" />
                                    <Tab label="Atualização de Status Sedex" value="2" />
                                </TabList>

                                <TabPanel value="0">
                                    <ImportacaoObjetoPostal 
                                        inicio={getInicioEnvioObjetosPostais2Fase(contexts)}
                                        termino={getTerminoEnvioObjetosPostais2Fase(contexts)}
                                    />
                                </TabPanel>
                                <TabPanel value="1">
                                    <ImportacaoPlanilhaObjetoPostal />
                                </TabPanel>
                                <TabPanel value="2">
                                    <AtualizacaoStatusSedex 
                                        inicio={getInicioRastreamentoObjetosPostais2Fase(contexts)}
                                        termino={getTerminoRastreamentoObjetosPostais2Fase(contexts)}
                                    />
                                </TabPanel>
                            </TabContext>
                        </Paper>
                    </Grid>
                </Grid>
            </Layout>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tarefa));