import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isPeriodoInscricoes, getPeriodoDePagamentoFormatado, isPeriodoPagamento, isPeriodoInscricoesParaEscolas } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert'
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Paper } from '@material-ui/core';

import EscolaService from '../../services/Escola';
import InscricaoService from '../../services/Inscricao';

import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import CheckBox from '../../components/Inputs/CheckBox/CheckBox';

import Endereco, { Endereco as EnderecoEntrega } from '../../forms/Endereco/Endereco';
import Email from '../../forms/Email/Email';
import Fase1 from '../../forms/Inscricao/Fase1';
import Modalidade from '../../forms/Inscricao/Modalidade';
import Telefone from '../../forms/Telefone/Telefone';
import { patternPhones } from '../../variables/Enums/Telefone';
import DependenciaAdministrativa from '../../forms/DependenciaAdministrativa/DependenciaAdministrativa';
import ResponsavelInscricao from '../../forms/ResponsavelInscricao/ResponsavelInscricao';
import Password from '../../forms/Password/Password';
import ButtonsForm from '../../forms/Buttons/ButtonsForm';

import { isMecCodeInvalid } from '../../helper/ValidationHelper';
import { filterPersistence } from '../../helper/ParseStateHelper';
import { cnpjMask } from '../../helper/MaskHelper';

import keycloak from '../../security/keycloak';

import { MenuContext } from '../../context/MenuContext';

import Private from '../../layouts/private/Private';
import Public from '../../layouts/public/Public';

export const InscricaoForm = props => {
    const [ keycloak, ] = useKeycloak();

    return (
        <>
            {keycloak.authenticated ?
                <Private>
                    <Form {...props} />
                </Private>
            :
                <Public>
                    <Container component="main" maxWidth="xl" style={{ marginTop: '24px' }}>
                        <Form {...props} />
                    </Container>
                </Public>
            }
        </>
    )
}

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingButtonFind: false,
      loadingButtonSave: false,
      mecCode: '',
      name: '',
      enderecosIguais: false,
      isInscrita: false,
      isInvalida: false,
      history: {
        path: null,
        state: null,
      },
      naoPodeFazerAlteracao: false,
    }
  }

  // CONTEXTO DE MENU
  static contextType = MenuContext;

  // FECHANDO MENU
  closeMenu = async () => {
    const { open, setOpen } = this.context;
    if (open) await setOpen(!open);
  }

  setToast = t => this.Toast = t;
  setDependenciaAdministrativa = d => this.DependenciaAdministrativa = d;
  setEmail = e => this.Email = e;
  setFase1 = f => this.Fase1 = f;
  setModalidade = m => this.Modalidade = m;
  setTelefone = t => this.Telefone = t;
  setEndereco = e => this.Endereco = e;
  setEnderecoEntrega = z => this.EnderecoEntrega = z;
  setPassword = p => this.Password = p;
  setResponsavelInscricao = r => this.ResponsavelInscricao = r;

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  }

  setEnderecoEscolaEmEnderecoEntrega = e => {
    if (e.target.checked) {
      this.EnderecoEntrega.findCities(this.Endereco.state.address.UF, this.Endereco.state.address.city);
      this.EnderecoEntrega.setState({
        address: {
          zipCode: this.Endereco.state.address.zipCode,
          address: this.Endereco.state.address.address ? this.Endereco.state.address.address.toUpperCase().trim() : "",
          number: this.Endereco.state.address.number,
          complement: this.Endereco.state.address.complement,
          district: this.Endereco.state.address.district ? this.Endereco.state.address.district.toUpperCase().trim() : "",
          city: this.Endereco.state.address.city,
          UF: this.Endereco.state.address.UF,
        }
      })
    } else {
      this.EnderecoEntrega.setState({
        address: {
          zipCode: '',
          address: '',
          number: '',
          complement: '',
          district: '',
          city: '',
          UF: '',
        }
      })
    }
  }

  async componentDidMount() {
    await this.props.loadContext();

    if (!isPeriodoInscricoes(this.props.contexts)) {
      this.Toast.setState({
          message: {
              message: `Ação de ${keycloak.authenticated ? `Atualizar` : `Iniciar` } Inscrição Inválida. Estamos Fora do Período de Inscrições.`,
              type: "error",
              open: true
          }
      })

      setTimeout(() => this.props.history.push(`/`), 3000);
    }

    this.setState({ ...this.state, loading: true, loadingButtonFind: true, history: filterPersistence(this.props.location) });

    const inep = !isNaN(this.props.match.params.id) ? this.props.match.params.id : '';

    if (isMecCodeInvalid(inep)) {
      this.handleProblemaBuscarEscola();
      return;
    }

    EscolaService.buscarParaInscricao(inep)
      .then(res => {
        if (res.data) {
          const school = res.data;

          if (school.inscricao && !keycloak.authenticated) {
            this.handleProblemaBuscarEscola("Escola Já Realizou Inscrição");
            setTimeout(() => this.props.history.push(`/inscricao`), 3000);

          } else {
            this.closeMenu();

            this.DependenciaAdministrativa.setState({
              ...this.DependenciaAdministrativa.state,
              adminDep: school.nmDependenciaAdministrativa,
              possuiConvenio : school.possuiConvenio ? school.possuiConvenio + "" : "false",
              depConvenio : school.dependenciaConvenio,
              tipoConvenio : school.dependenciaAlunoConvenio,
              cnpj : school.cnpj ? cnpjMask(school.cnpj) : '',
              disabled: {
                adminDep: true,
                possuiConvenio: school.inscricao ? true : false,
                depConvenio: school.inscricao ? true : false,
                tipoConvenio: school.inscricao ? true : false,
              }
            })

            this.Telefone.setState({
              ...this.Telefone.state,
              phones: [...patternPhones(school.telefones)],
            })

            this.Endereco.findCities(school.endereco.municipio.uf.cdUf, school.endereco.municipio.cdMunicipioRegiao);
            this.Endereco.setState({
              ...this.Endereco.state,
              address: {
                zipCode: school.endereco.nmCep,
                address: school.endereco.nmEndereco,
                number: school.endereco.nmNumero,
                complement: school.endereco.nmComplemento,
                district: school.endereco.nmBairro,
                city: school.endereco.municipio.cdMunicipioRegiao,
                UF: school.endereco.municipio.uf.cdUf,
              }
            })

            if (school.enderecoEntrega != null) {
              this.EnderecoEntrega.findCities(school.enderecoEntrega.municipio.uf.cdUf, school.enderecoEntrega.municipio.cdMunicipioRegiao);
              this.EnderecoEntrega.setState({
                ...this.EnderecoEntrega.state,
                address: {
                  zipCode: school.enderecoEntrega.nmCep,
                  address: school.enderecoEntrega.nmEndereco,
                  number: school.enderecoEntrega.nmNumero,
                  complement: school.enderecoEntrega.nmComplemento,
                  district: school.enderecoEntrega.nmBairro,
                  city: school.enderecoEntrega.municipio.cdMunicipioRegiao,
                  UF: school.enderecoEntrega.municipio.uf.cdUf,
                }
              })
            }

            if (school.responsavel != null) {
              this.ResponsavelInscricao.setState({
                ...this.ResponsavelInscricao.state,
                nome: school.responsavel.nmNome,
                cpf: school.responsavel.nmCpf,
                cargo: school.responsavel.nmCargo,
              })

              this.ResponsavelInscricao.Email.setState({
                ...this.ResponsavelInscricao.Email.state,
                email: school.responsavel.nmEmail,
                emailOriginal: school.responsavel.nmEmail,
              })

              this.ResponsavelInscricao.Telefone.setState({
                ...this.ResponsavelInscricao.Telefone.state,
                phones: [...patternPhones(school.responsavel.telefones)],
              })
            }

            if (school.inscricao != null) {
              this.Fase1.setState({
                ...this.Fase1.state,
                  adminDep: school.nmDependenciaAdministrativa,
                  possuiConvenio : school.possuiConvenio ? school.possuiConvenio + "" : "false",
                  dependenciaConvenio : school.dependenciaConvenio,
                  modalidade: school.inscricao.modalidade,
                  inscricaoMirim1QtdAlunosPrevistos: school.inscricao.mirim1.qtdAlunosPrevistos,
                  inscricaoMirim2QtdAlunosPrevistos: school.inscricao.mirim2.qtdAlunosPrevistos,
                  valorPrevisto : school.inscricao.valorPrevisto,
                  disabled: {
                    inscricaoMirim1QtdAlunosPrevistos: !isPeriodoInscricoes(this.props.contexts),
                    inscricaoMirim2QtdAlunosPrevistos: !isPeriodoInscricoes(this.props.contexts),
                  },
              })

              this.Modalidade.setState({
                ...this.Modalidade.state,
                  adminDep: school.nmDependenciaAdministrativa,
                  dependenciaConvenio : school.dependenciaConvenio,
                  modalidade: school.inscricao.modalidade,
                  disabled: {
                    modalidade: !isPeriodoInscricoesParaEscolas(this.props.contexts),
                  },
              })
            } else {
              this.Fase1.setState({
                ...this.Fase1.state,
                  adminDep: school.nmDependenciaAdministrativa,
              })
              this.Modalidade.setState({
                ...this.Modalidade.state,
                modalidade: school.nmDependenciaAdministrativa === "PRIVADA" ? "MODALIDADE_TRES" : "MODALIDADE_UM",
                adminDep: school.nmDependenciaAdministrativa,
                dependenciaConvenio : school.dependenciaConvenio,
                disabled: {
                  modalidade: !isPeriodoInscricoesParaEscolas(this.props.contexts),
                },
              })
            }

            this.Email.setState({
              ...this.Email.state,
              email: school.nmEmail,
              emailOriginal: school.nmEmail,
            })

            this.setState({
              ...this.state,
              mecCode: school.cdMecEscola,
              name: school.nmEscola,
              isInscrita: school.inscricao ? true : false,
              isInvalida: false,
              naoPodeFazerAlteracao: isPeriodoInscricoes(this.props.contexts) ? false : isPeriodoPagamento(this.props.contexts) ? true : false,
            });
          }
        } else {
          this.handleProblemaBuscarEscola("Escola Não Encontrada. Verifique se o Código INEP está correto e Tente Novamente");
          setTimeout(() => this.props.history.push(`/inscricao`), 3000);
        }
      })
      .catch(error => {
        this.handleProblemaBuscarEscola();
      })
      .finally(() => this.setState({ loading: false, loadingButtonFind: false }))
  }

  dependenciaAdministrativaComponentChanged = (state) => { this.Fase1.changeDepAdmin(state); this.Modalidade.changeDepAdmin(state); }
  modalidadeComponentChanged = (state) => this.Fase1.changeModalidade(state);

  handleSubmit = async () => {
    this.setState({ loadingButtonSave: true });

    const formEmailIsValid = await this.Email.isValid();
    const formEnderecoEntregaIsValid = this.EnderecoEntrega.isValid();
    const formAdmDepIsValid = this.DependenciaAdministrativa.isValid();
    const formFase1IsValid = this.Fase1.isValid();
    const formModalidadeIsValid = this.Modalidade.isValid();
    const formPasswordIsValid = this.Password.isValid();
    const formResponsavelIsValid = await this.ResponsavelInscricao.isValid();
    const formTelefoneIsValid = this.Telefone.isValidInFormToSave();
    const formTelefoneResponsavelIsValid = this.ResponsavelInscricao.Telefone.isValidInFormToSave();

    if (!formFase1IsValid || !formModalidadeIsValid || !formEmailIsValid || !formEnderecoEntregaIsValid || !formAdmDepIsValid || !formTelefoneIsValid || !formTelefoneResponsavelIsValid || !formResponsavelIsValid
      || (!formPasswordIsValid && !this.state.isInscrita)) {
      this.setState({ loadingButtonSave: false });
      this.Toast.setState({
        message: {
          message: `Houve um problema de validação nos dados informados no formulário. Revise as informações preenchidas e tente novamente.`,
          type: "warning",
          open: true
        }
      })
      return
    }

    const inscricao = {
      nmNomeResponsavel: this.ResponsavelInscricao.state.nome.toUpperCase().trim().replaceAll(/\s+/g, " "),
      nmEmailResponsavel: this.ResponsavelInscricao.Email.state.email,
      nmCpfResponsavel: this.ResponsavelInscricao.state.cpf,
      nmCargoResponsavel: this.ResponsavelInscricao.state.cargo,
      telefonesResponsavel: [...this.ResponsavelInscricao.Telefone.state.phones],

      nmEmail: this.Email.state.email,
      telefones: [...this.Telefone.state.phones],

      inscricaoMirim1QtdAlunosPrevistos: this.Fase1.state.inscricaoMirim1QtdAlunosPrevistos,
      inscricaoMirim2QtdAlunosPrevistos: this.Fase1.state.inscricaoMirim2QtdAlunosPrevistos,

      modalidade: this.Modalidade.state.modalidade,

      possuiConvenio: this.DependenciaAdministrativa.state.possuiConvenio,
      dependenciaConvenio: this.DependenciaAdministrativa.state.depConvenio,
      dependenciaAlunoConvenio: this.DependenciaAdministrativa.state.tipoConvenio,
      cnpj: this.DependenciaAdministrativa.state.cnpj,

      nmCep: this.EnderecoEntrega.state.address.zipCode,
      nmEndereco: this.EnderecoEntrega.state.address.address.toUpperCase().trim(),
      nmNumero: this.EnderecoEntrega.state.address.number,
      nmComplemento: this.EnderecoEntrega.state.address.complement,
      nmBairro: this.EnderecoEntrega.state.address.district.toUpperCase().trim(),
      cdMunicipioRegiao: this.EnderecoEntrega.state.address.city,

      password: !this.state.isInscrita ? this.Password.state.password : null,
      passwordTemporario: !this.state.isInscrita ? this.Password.state.passwordTemporario : null,
    }

    let request = null;
    let mensagem = null;
    
    if (this.state.isInscrita) {
      request = InscricaoService.edit(this.state.mecCode, inscricao);
      mensagem = "Atualização Realizada com Sucesso.";
    } else {
      request = InscricaoService.add(this.state.mecCode, inscricao);
      
      const dependenciaAdministrativaPrivada = this.DependenciaAdministrativa.state.adminDep === "PRIVADA";
      const possuiConvenio =  this.DependenciaAdministrativa.state.possuiConvenio === "true";
      const tipoConvenioTotal = this.DependenciaAdministrativa.state.depConvenio === "TOTAL";
      const modalidadeSelecionada = this.Modalidade.state.modalidade;

      if (modalidadeSelecionada === "MODALIDADE_QUATRO" || modalidadeSelecionada === "MODALIDADE_TRES") {
          mensagem = `Sua inscrição só será validada após a emissão e pagamento do boleto!.<br /> Emissão de boleto: <b>${getPeriodoDePagamentoFormatado(this.props.contexts)}</b>.<br /><br />`;

      } else if (modalidadeSelecionada === "MODALIDADE_DOIS") {

        if (dependenciaAdministrativaPrivada && possuiConvenio && tipoConvenioTotal) {
          mensagem = `Sua inscrição só será validada após o envio de documento solicitado por e-mail!<br /><br />`;
        } else if (!dependenciaAdministrativaPrivada) {
          mensagem = `Sua inscrição foi concluída com sucesso!<br />Emissão de boleto: <b>${getPeriodoDePagamentoFormatado(this.props.contexts)}</b>.<br /><br />`;
        }

      } else if (modalidadeSelecionada === "MODALIDADE_UM") {

        if (dependenciaAdministrativaPrivada && possuiConvenio && tipoConvenioTotal) {
          mensagem = `Sua inscrição só será validada após o envio de documento solicitado por e-mail!<br /><br />`;
        } else if (!dependenciaAdministrativaPrivada) {
          mensagem = `Sua inscrição foi concluída com sucesso!<br /><br />`;
        }
      }

      mensagem += `${!keycloak.authenticated ? `Em alguns instantes você será redirecionado a área de login do sistema.` : `Inscrição realizada com sucesso.`}`;
    }

    request
      .then(res => {
        this.Toast.setState({
          message: {
            message: mensagem,
            type: "success",
            open: true
          }
        })

        setTimeout(() => !keycloak.authenticated ? keycloak.login({ redirectUri: process.env.REACT_APP_PUBLIC_URL }) : this.handleBack(), 4000);
      })
      .catch(err => {
        this.Toast.setState({
          message: {
            message: `Ocorreu um Problema ao ${this.state.isInscrita && keycloak.authenticated ? `Atualizar` : `Realizar`} a inscrição`,
            type: "error",
            open: true
          }
        })
      })
      .finally(() => this.setState({ loadingButtonSave: false }));
  }

  handleProblemaBuscarEscola = (message = "Ocorreu um Problema ao Buscar a Escola.") => {
    this.Toast.setState({
      message: {
        message: message,
        type: "error",
        open: true
      }
    })
  }

  handleCancelarIncricao = () => {
    this.props.history.push(`/inscricao`);
  }

  handleBack = () => {
    if (this.state.history && this.state.history.path) {
      this.props.history.push({
        pathname: `${this.state.history.path}`,
        state: {
          history: {
            state: this.state.history.state
          }
        }
      });
    } else {
      this.props.history.goBack();
    }
  }

  render() {
    const page = `Olimpíada Mirim - Ficha de Inscrição da Escola`;

    return (
      <>
        <Toast parentRef={this.setToast} />

        <Container component="main" maxWidth="xl">
          <Box mt={keycloak.authenticated ? 2 : 10}>
            <Title>{page}</Title>
            <>
              <Grid container spacing={3}>
                <Grid item sm={12} lg={12}>
                  <Alert severity="info">
                    Preencha <b>&nbsp;TODOS&nbsp;</b> os campos abaixo para {!this.state.isInscrita ? `realizar` : `editar`} sua inscrição.
                  </Alert>
                </Grid>
              </Grid>

              <Grid container spacing={3} style={{ marginTop: 18 }}>
                <Grid item sm={12} lg={12}>
                  <Typography variant="h4">{this.state.name} - <small>{this.state.mecCode}</small></Typography>
                </Grid>
              </Grid>

              <Paper elevation={3} style={{ padding: 24, marginTop: 24 }}>
                <Grid item sm={12} lg={9}>
                  <Typography variant="body2" style={{ fontSize: 16 }}><b>Dados do Responsável Pela Inscrição</b></Typography>
                </Grid>

                <ResponsavelInscricao parentRef={this.setResponsavelInscricao} />
              </Paper>

              <Paper elevation={3} style={{ padding: 24, marginTop: 24 }}>
                <Grid item sm={12} lg={9}>
                  <Typography variant="body2" style={{ fontSize: 16 }}><b>Dados Gerais da Escola</b></Typography>
                </Grid>

                <DependenciaAdministrativa onChange={this.dependenciaAdministrativaComponentChanged} parentRef={this.setDependenciaAdministrativa} withFederal={true} create={false} />
              </Paper>

              <Paper elevation={3} style={{ padding: 24, marginTop: 24 }}>
                <Grid item sm={12} lg={9}>
                  <Typography variant="body2" style={{ fontSize: 16 }}><b>Dados de Endereço da Escola</b></Typography>
                </Grid>

                <Endereco parentRef={this.setEndereco} disabled={true} />
              </Paper>

              <Paper elevation={3} style={{ padding: 24, marginTop: 24 }}>
                <Grid item sm={12} lg={9}>
                  <Typography variant="body2" style={{ fontSize: 16 }}><b>Dados de Contato da Escola</b></Typography>
                </Grid>

                <Email parentRef={this.setEmail} name="Email da Escola" />
                <Telefone parentRef={this.setTelefone} />
              </Paper>

              <Paper elevation={3} style={{ padding: 24, marginTop: 24 }}>
                <Grid item sm={12} lg={9}>
                  <Typography variant="body2" style={{ fontSize: 16 }}><b>Definir Modalidade de Participação da Escola</b></Typography>
                </Grid>

                <Modalidade parentRef={this.setModalidade} onChange={this.modalidadeComponentChanged} />
              </Paper>

              <Paper elevation={3} style={{ padding: 24, marginTop: 24 }}>
                <Grid item sm={12} lg={9}>
                  <Typography variant="body2" style={{ fontSize: 16 }}><b>Quantidade de Alunos por Faixa de Ano Letivo</b></Typography>
                </Grid>

                <Fase1 parentRef={this.setFase1} />
              </Paper>

              <Paper elevation={3} style={{ padding: 24, marginTop: 24 }}>
                <Grid container>
                  <Grid item sm={5} lg={5}>
                    <Typography variant="body2" style={{ fontSize: 16 }}><b>Informe o Endereço para Envio do Material da 2ª Fase, no mesmo Município da Escola</b></Typography>
                  </Grid>
                  <CheckBox
                    name="enderecosIguais"
                    label="Endereço de Entrega Igual ao Endereço da Escola"
                    checked={this.state.enderecosIguais}
                    onChange={(e) => { this.handleChange(e); this.setEnderecoEscolaEmEnderecoEntrega(e); }}
                  />
                </Grid>

                <EnderecoEntrega parentRef={this.setEnderecoEntrega} municipioValido={this.Endereco ? this.Endereco.state.address.city : null} />
              </Paper>

              {!this.state.isInscrita &&
                <Paper elevation={3} style={{ padding: 24, marginTop: 24 }}>
                  <Grid item sm={12} lg={9}>
                    <Typography variant="body2" style={{ fontSize: 16 }}><b>Crie uma Senha de Acesso ao site</b></Typography>
                  </Grid>

                  <Password parentRef={this.setPassword} />
                </Paper>
              }

              {this.state.isInscrita ?
                <ButtonsForm
                  onClick={this.handleSubmit}
                  onBack={this.handleBack}
                  loading={this.state.loadingButtonSave}
                  name={"Salvar Inscrição"}
                  nameOnBack={"Voltar"}
                  idFocus={`saveButton`}
                  saveDisabled={this.state.naoPodeFazerAlteracao}
                />
                :
                <ButtonsForm
                  onClick={this.handleSubmit}
                  onBack={!keycloak.authenticated ? this.handleCancelarIncricao : this.handleBack}
                  loading={this.state.loadingButtonSave}
                  name={"Realizar Inscrição"}
                  nameOnBack={!keycloak.authenticated ? "Cancelar e Voltar ao Início" : "Voltar"}
                  idFocus={`saveButton`}
                />
              }
            </>
          </Box>
        </Container>
      </>
    )
  }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InscricaoForm));