import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import Table from '../../components/Table/Table';
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Loading/Loading';
import Header from '../../components/Header/Header';
import {
    verifyPagingAndSorting,
    PAGE,
    ASC,
    TOTAL_ELEMENTS,
    TOTAL_PAGES,
} from '../../components/Table/Utils';

import EscolaPublicoFilter from '../../forms/Escola/EscolaPublicoFilter';

import { patternAdminDep } from '../../variables/Enums/DependenciaAdministrativa';
import { patternSchools, patternLabelStatusInscricaoPaginaPublica } from '../../variables/Enums/Escola';

import { removePagingAndSorting } from '../../helper/PaginationHelper';

import Layout from '../../layouts/public/Public';

export class EscolasPublico extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: PAGE,
            size: 50,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            sort: [{ orderBy: 'cdMecEscola', order: ASC }],
            defaultOrderBy: 'cdMecEscola',
            filtered: false,
            filters: [],
            schools: [],
            loading: false,
        }
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setEscolaPublicoFilter = e => this.EscolaPublicoFilter = e;

    // FILTROS
    loadData = () => {
        let filters = verifyPagingAndSorting(this.state);

        this.setState({
          filters: [...filters],
          filtered: true,
          loading: true
        });

        RelatorioService.filtersReportAllSchoolsPublic(filters)
            .then(res => {
                if (res.data.content) {
                    const schools = patternSchools(res.data.content);
                    this.setState({
                        ...this.state,
                        schools: [...schools],
                        page: res.data.number,
                        size: res.data.size,
                        totalElements: res.data.totalElements,
                        totalPages: res.data.totalPages,
                        loading: false
                    })

                    this.EscolaPublicoFilter.handleFilterPersistence(filters);
                } else {
                    this.setState({
                        schools: [],
                        page: PAGE,
                        size: 50,
                        totalElements: TOTAL_ELEMENTS,
                        totalPages: TOTAL_PAGES,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
    }

    // FILTROS
    filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

    // PÁGINA
    handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

    // LINHAS POR PÁGINA
    handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

    // ORDENAÇÃO
    handleSort = (orderBy, order) => {
        if (this.state.sort.find(s => s.orderBy === orderBy)) {
            let newSort = this.state.sort.filter(s => s.orderBy !== orderBy);
            newSort.splice(this.state.sort.indexOf(this.state.sort.find(s => s.orderBy === orderBy)), 0, { orderBy: orderBy, order: order })
            this.setState({...this.state, sort: [...newSort], filtered: true });
        } else {
            this.setState({ ...this.state, sort: [...this.state.sort.filter(s => s.orderBy !== orderBy), { orderBy: orderBy, order: order }], filtered: true });
        }
    }
    handleClearSort = (orderBy) => {
        this.setState({ ...this.state, filtered: true, sort: orderBy ? [...this.state.sort.filter(s => s.orderBy !== orderBy)] : [{ orderBy: this.state.defaultOrderBy, order: ASC }] });
    };

    // TOTAL DE PAGINAS
    handleTotalPages = () => this.state.totalPages;

    // TOTAL DE ELEMENTOS
    handleTotalElements = () => this.state.totalElements;

    // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
    handleFilterPersistence = () => this.EscolaPublicoFilter ? this.EscolaPublicoFilter.state ? this.EscolaPublicoFilter.handleFilterPersistence(this.state.filters) : null : null;

    render() {
        const columns = [
            { label: 'INEP', name: 'cdMecEscola', func: null, key: true, witdh: '10', },
            { label: 'Escola', name: 'nmEscola', func: null, key: false, width: '25', },
            { label: 'Dep. Adm. ', name: 'nmDependenciaAdministrativa', func: patternAdminDep, key: false, witdh: '10', },
            { label: 'Status de Inscrição', name: 'statusInscricao', func: patternLabelStatusInscricaoPaginaPublica, key: false, width: '15', ordering: false },
            { label: 'Municipio', name: 'nmMunicipio', func: null, key: false, witdh: '25', ordering: false, },
            { label: 'UF', name: 'nmUf', func: null, key: false, witdh: '15', ordering: false, },
        ];

        const actions = [];

        const pageName = 'Lista de Escolas';

        return (
            <Layout>
                <Header className="no-print" />

                <div style={{ margin: '54px', marginTop: '86px', marginBottom: '86px' }}>
                    <Title variant="h3">
                        EM BREVE
                    </Title>
                
                    {/*

                    <Toast parentRef={this.setToast} />
                    <Title className="no-print">{pageName}</Title>

                    <Grid container spacing={3} style={{ marginBottom: 8 }} className="no-print">
                        <Grid item sm={12} lg={12}>
                            <Typography variant="body1"><b>Utilize o Filtro abaixo para iniciar a busca.</b></Typography>
                        </Grid>
                    </Grid>

                    <EscolaPublicoFilter
                        parentRef={this.setEscolaPublicoFilter}
                        handleFilterChange={this.filterData}
                    />

                    {this.state.loading ?
                        <Loading />
                        :
                        <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom >Lista de Escolas</Typography>
                                <Table
                                    columns={columns}
                                    tableData={this.state.schools}
                                    actions={actions}
                                    page={this.state.page}
                                    handlePage={this.handlePage}
                                    handleTotalPages={this.handleTotalPages}
                                    handleTotalElements={this.handleTotalElements}
                                    rowsPerPage={this.state.size}
                                    handleRowsPerPage={this.handleRowsPerPage}
                                    sort={this.state.sort}
                                    handleSort={this.handleSort}
                                    handleClearSort={this.handleClearSort}
                                    loading={this.state.loading}
                                    mostrarTodos={false}
                                />
                            </Grid>
                        </Grid>
                    }
                    
                    */}
                </div>
            </Layout>
        )
    }
}

export default withRouter(EscolasPublico);